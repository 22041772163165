<template>
    <div class="safer-world-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="safer-world-image">
                        <img src="../../assets/images/more-home/safer-world/safer-world.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="safer-world-content">
                        <h3>We create a safer Virtual World For Your future</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit. Adipiscing eleifend dictum potenti mattis viverra. Eget quam lacus enim porttitor bibendum elit dui nisl.</p>

                        <div class="row justify-content-center">
                            <div class="col-lg-6 col-md-6">
                                <div class="safer-world-inner-box">
                                    <h4>Our Mission</h4>
                                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit. Adipisc ing eleifend dictum pot nti.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="safer-world-inner-box">
                                    <h4>Our Mission</h4>
                                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit. Adipisc ing eleifend dictum pot nti.</p>
                                </div>
                            </div>
                        </div>

                        <div class="safer-world-btn">
                            <router-link to="/about-us" class="default-btn">Know More About Us</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SaferWorld'
}
</script>