<template>
    <div class="approach-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="approach-img">
                        <img src="../../assets/images/approach-img.jpg" alt="Image">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="approach-content">
                        <h2>Our Approach To Security</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsumv</p>

                        <ul>
                            <li>
                                <i class="flaticon-cyber"></i>
                                <h3>Secure by Design</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut  suspendisse ultrices</p>
                            </li>

                            <li>
                                <i class="flaticon-cyber-security"></i>
                                <h3>Compliant by Design</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut  suspendisse ultrices</p>
                            </li>

                            <li>
                                <i class="flaticon-profile"></i>
                                <h3>Continuous Monitoring</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut  suspendisse ultrices</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Approach'
}
</script>