<template>
    <footer class="footer-bottom-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="copy-right">
                        <p>&copy; {{currentYear}} shop&mag</p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="condition-privacy">
                        <ul>
                            <li>
                                <router-link to="/terms-conditions">Terms & Condition</router-link>
                            </li>
                            <li>
                                <router-link to="/privacy-policy">Privacy Policy</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterBottom',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>