<template>
    <footer class="footer-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="single-footer-widget">
                        <router-link to="/" class="logo">
                            <img src="../../assets/images/logo-black.png" alt="Image">
                        </router-link>

                        <p>Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et. Lorem ipsum dolor sit amet</p>

                        <ul class="social-icon">
                            <li>
                                <a href="https://www.facebook.com/">
                                    <i class="bx bxl-facebook"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/">
                                    <i class="bx bxl-instagram"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/">
                                    <i class="bx bxl-linkedin-square"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.twitter.com/">
                                    <i class="bx bxl-twitter"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-footer-widget">
                        <h3>Help</h3>

                        <ul class="import-link">
                            <li>
                                <router-link to="/privacy-policy">Privacy Policy</router-link>
                            </li>
                            <li>
                                <router-link to="/privacy-policy">Return Policy</router-link>
                            </li>
                            <li>
                                <router-link to="terms-conditions">Termas & Conditions</router-link>
                            </li>
                            <li>
                                <router-link to="/about-us">About Us</router-link>
                            </li>
                            <li>
                                <router-link to="/about-us">Careers</router-link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-footer-widget">
                        <h3>Services Link</h3>

                        <ul class="import-link">
                            <li>
                                <router-link to="/contact">Threat Hunter</router-link>
                            </li>
                            <li>
                                <router-link to="/contact">Incident Responder</router-link>
                            </li>
                            <li>
                                <router-link to="/contact">Secure Managed IT</router-link>
                            </li>
                            <li>
                                <router-link to="/contact">Compliance</router-link>
                            </li>
                            <li>
                                <router-link to="/contact">Cyber Security</router-link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="single-footer-widget">
                        <h3>Contacts</h3>

                        <ul class="address">
                            <li class="location">
                                <span>Address:</span>
                                2750 Quadra Street Victoria, Canada.
                            </li>
                            <li>
                                <span>Email:</span>
                                <a href="mailto:hello@quvia.com">hello@quvia.com</a>
                            </li>
                            <li>
                                <span>Phone:</span>
                                <a href="tel:+44-587-154756">+44 587 154756</a>
                            </li>
                            <li>
                                <span>Fax:</span>
                                <a href="tel:+44-785-4578964">+44 785 4578964</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>

export default {
    name: 'FooterStyleTwo'
}
</script>