<template>
    <div class="complete-website-security-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <h2>You Get Complete Website Security From Us</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit. Adipiscing eleifend dictum potenti mattis viverra eget quam lacus enim porttitor bibendum elit dui nisl.</p>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-3 col-sm-6">
                    <div class="complete-website-security-card mb-25">
                        <div class="icon">
                            <i class="flaticon-bug"></i>
                        </div>
                        <h3>
                            <router-link to="/services-details">Malware Detection Removal</router-link>
                        </h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit adiing eleifend dictum pot nti.</p>
                        <div class="security-shape">
                            <img src="../../assets/images/more-home/website-security/security-shape-2.png" alt="image">
                        </div>
                    </div>	
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="complete-website-security-card mb-25">
                        <div class="icon">
                            <i class="flaticon-content"></i>
                        </div>
                        <h3>
                            <router-link to="/services-details">Content Delivery Network</router-link>
                        </h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit adiing eleifend dictum pot nti.</p>
                        <div class="security-shape">
                            <img src="../../assets/images/more-home/website-security/security-shape-2.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="complete-website-security-card mb-25">
                        <div class="icon">
                            <i class="flaticon-support"></i>
                        </div>
                        <h3>
                            <router-link to="/services-details">24/7 Cyber Security Support</router-link>
                        </h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit adiing eleifend dictum pot nti.</p>
                        <div class="security-shape">
                            <img src="../../assets/images/more-home/website-security/security-shape-2.png" alt="image">
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="complete-website-security-card mb-25">
                        <div class="icon">
                            <i class="flaticon-profile"></i>
                        </div>
                        <h3>
                            <router-link to="/services-details">Managed Web Application</router-link>
                        </h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit adiing eleifend dictum pot nti.</p>
                        <div class="security-shape">
                            <img src="../../assets/images/more-home/website-security/security-shape-2.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="website-security-shape">
            <img src="../../assets/images/more-home/website-security/security-shape-1.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'CompleteWebsite'
}
</script>