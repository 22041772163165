<template>
  <div class="page-title-area bg-1"
    :style="{ backgroundImage: `url(${require('@/assets/images/performance-bg.jpg')})` }">
  <div class="container">
    <img src="../../assets/images/ShopAndMag-Logo-Header.png" alt="image">
    <div class="page-title-content">
      <!--<h2>{{obj.linkText}}</h2>-->
      <ul>
        <li>
          <router-link to="/">
            Anasayfa
          </router-link>
        </li>
        <li class="active">Shop&Mag</li>
      </ul>
    </div>
  </div>
</div></template>

<script>
import NavbarItemsJson from "../../assets/data/NavbarItems.json";

export default {
  name: 'PageTitle',
  props: ["PostsData"],
  created() {
    // console.log("yeniiii")
    this.navbarItems = this.navbarItems.filter(x => x.child === true)

    this.obj = this.navbarItems.reduce((result, menu) => {
      const subMenu = menu.submenu.find(item => item.id === parseInt(this.$route.params.id));
      if (subMenu) {
        result = subMenu;
      }
      return result;
    }, null);



  },
  data() {
    return {
      obj: {},
      navbarItems: NavbarItemsJson.data,
    };
  },
}
</script>


