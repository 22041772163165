<template>
    <div class="manual-area bg-color ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="manual-img">
                        <img src="../../assets/images/manual-img.png" alt="Image">
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="manual-content me-auto ms-0">
                        <h2>How to Protect Your Website: The Manual Way</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>

                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <ul class="cybersecurity-item">
                                    <li>
                                        <i class="bx bx-check"></i>
                                        Managed Web Application
                                    </li>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        SIEM Threat Detection
                                    </li>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        Content Delivery Network
                                    </li>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        Website Hack Repair
                                    </li>
                                </ul>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <ul>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        Instant Malware Removal
                                    </li>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        Instant Malware Removal
                                    </li>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        Instant Malware Removal
                                    </li>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        Instant Malware Removal
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <a href="#" class="default-btn mt-30">
                            Know Details
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ManualArea'
}
</script>