<template>
    <div>
        <Navbar />
        <PageTitle />
        <TermsConditions />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../TermsConditions/PageTitle'
import TermsConditions from '../TermsConditions/TermsConditions'
import FooterTop from '../Layout/FooterTop'
import FooterBottom from '../Layout/FooterBottom'

export default {
    name: 'TermsConditionsPage',
    components: {
        Navbar,
        PageTitle,
        TermsConditions,
        FooterTop,
        FooterBottom,
    }
}
</script>
