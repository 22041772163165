<template>
    <div class="banner-area banner-area-two">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="banner-text">
                                <span>All Research up to Blockchain Interoperability is completed</span>
                                <h1>A Team of Professionals To Protect your Team</h1>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil architecto laborum eaque! Deserunt maxime, minus quas molestiae reiciendis esse natus nisi iure.</p>
    
                                <div>
                                    <router-link class="default-btn" to="/pricing">
                                        Booking Now
                                    </router-link>
                                    <router-link class="default-btn active" to="/about-us">
                                        About Us
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-lg-6">
                            <div class="banner-site-img">
                                <img src="../../assets/images/banner-site-img1.png" alt="Image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Banner'
}
</script>