<template>
    <div class="complete-website-security-area ptb-100">
        <div class="container">
            <div class="section-title-wrap">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <h2>Our Included Service</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit. Adipiscing eleifend dictum potenti mattis viverra eget quam lacus enim porttitor bibendum elit dui nisl.</p>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="wrap-btn">
                            <router-link to="/services-one" class="default-btn">View All Services</router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div class="complete-website-security-slides">
                <carousel 
                    :autoplay="5000"
                    :settings="settings"
                    :breakpoints="breakpoints"
                >
                    <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div class="complete-website-security-card with-white-color">
                            <div class="icon">
                                <i :class="slide.icon"></i>
                            </div>
                            <h3>
                                <router-link to="/services-details">{{slide.title}}</router-link>
                            </h3>
                            <p>{{slide.description}}</p>
                            <div class="security-shape">
                                <img src="../../assets/images/more-home/website-security/security-shape-2.png" alt="image">
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <Navigation />
                    </template>
                </carousel>
            </div>
        </div>

        <div class="website-security-shape">
            <img src="../../assets/images/more-home/website-security/security-shape-1.png" alt="image">
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'IncludedService',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                icon: 'flaticon-bug',
                title: 'Malware Detection Removal',
                description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit adiing eleifend dictum pot nti.',
            },
            {
                id: 2,
                icon: 'flaticon-content',
                title: 'Content Delivery Network',
                description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit adiing eleifend dictum pot nti.',
            },
            {
                id: 3,
                icon: 'flaticon-support',
                title: '24/7 Cyber Security Support',
                description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit adiing eleifend dictum pot nti.',
            },
            {
                id: 4,
                icon: 'flaticon-profile',
                title: 'Managed Web Application',
                description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit adiing eleifend dictum pot nti.',
            },
            {
                id: 5,
                icon: 'flaticon-bug',
                title: 'Malware Detection Removal',
                description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit adiing eleifend dictum pot nti.',
            },
            {
                id: 6,
                icon: 'flaticon-content',
                title: 'Content Delivery Network',
                description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit adiing eleifend dictum pot nti.',
            },
            {
                id: 7,
                icon: 'flaticon-support',
                title: '24/7 Cyber Security Support',
                description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit adiing eleifend dictum pot nti.',
            },
            {
                id: 8,
                icon: 'flaticon-profile',
                title: 'Managed Web Application',
                description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit adiing eleifend dictum pot nti.',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
        },
    }),
})
</script>