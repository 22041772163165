<template>
    <div class="features-area-page pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="single-features">
                        <h3><i class="bx bx-check-shield"></i> Security</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <span class="bx bx-check-shield"></span>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-features">
                        <h3><i class="bx bx-lock"></i> Privacy</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <span class="bx bx-lock"></span>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="single-features">
                        <h3><i class="bx bx-certification"></i> Industry Certified</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <span class="bx bx-certification"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Features'
}
</script>