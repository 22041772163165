import {createWebHistory, createRouter} from "vue-router";

// All Pages
import HomePageZero from "../components/pages/HomePageZero";
import HomePageOne from "../components/pages/HomePageOne";
import HomePageTwo from "../components/pages/HomePageTwo";
import HomePageThree from "../components/pages/HomePageThree";
import HomePageFour from "../components/pages/HomePageFour";
import HomePageFive from "../components/pages/HomePageFive";
import HomePageSix from "../components/pages/HomePageSix";
import HomePageSeven from "../components/pages/HomePageSeven";
import HomePageEight from "../components/pages/HomePageEight";
import HomePageNine from "../components/pages/HomePageNine";
import AboutUsPage from "../components/pages/AboutUsPage";
import PricingPage from "../components/pages/PricingPage";
import TestimonialsPage from "../components/pages/TestimonialsPage";
import TeamPage from "../components/pages/TeamPage";
import FaqPage from "../components/pages/FaqPage";
import TermsConditionsPage from "../components/pages/TermsConditionsPage";
import PrivacyPolicyPage from "../components/pages/PrivacyPolicyPage";
import LogInPage from "../components/pages/LogInPage";
import SignInPage from "../components/pages/SignInPage";
import RecoverPasswordPage from "../components/pages/RecoverPasswordPage";
import ErrorPage from "../components/pages/ErrorPage";
import ServicesStyleOnePage from "../components/pages/ServicesStyleOnePage";
import ServicesStyleTwoPage from "../components/pages/ServicesStyleTwoPage";
import ServicesStyleThreePage from "../components/pages/ServicesStyleThreePage";
import ServicesDetailsPage from "../components/pages/ServicesDetailsPage";
import BlogGridPage from "../components/pages/BlogGridPage";
import BlogLeftSidebarPage from "../components/pages/BlogLeftSidebarPage";
import BlogRightSidebarPage from "../components/pages/BlogRightSidebarPage";
import BlogDetailsPage from "../components/pages/BlogDetailsPage";
import umbraco from "@/components/umbraco.vue";
// import Image from "../components/BlogDetails/ImageMap";
import ContactPage from "../components/pages/ContactPage";

const routes = [
    {path: "/", component: HomePageZero},
    {path: "/home-one", component: HomePageOne},
    {path: "/home-two", component: HomePageTwo},
    {path: "/home-three", component: HomePageThree},
    {path: "/home-four", component: HomePageFour},
    {path: "/home-five", component: HomePageFive},
    {path: "/home-six", component: HomePageSix},
    {path: "/home-seven", component: HomePageSeven},
    {path: "/home-eight", component: HomePageEight},
    {path: "/home-nine", component: HomePageNine},
    {path: "/about-us", component: AboutUsPage},
    {path: "/pricing", component: PricingPage},
    {path: "/testimonials", component: TestimonialsPage},
    {path: "/team", component: TeamPage},
    {path: "/faq", component: FaqPage},
    {path: "/terms-conditions", component: TermsConditionsPage},
    {path: "/privacy-policy", component: PrivacyPolicyPage},
    {path: "/log-in", component: LogInPage},
    {path: "/sign-in", component: SignInPage},
    {path: "/recover-password", component: RecoverPasswordPage},
    {path: "/error", component: ErrorPage},
    {path: "/services-one", component: ServicesStyleOnePage},
    {path: "/services-two", component: ServicesStyleTwoPage},
    {path: "/services-three", component: ServicesStyleThreePage},
    {path: "/services-details", component: ServicesDetailsPage},
    {path: "/blog-grid/:id", component: BlogGridPage, props: true},
    {path: "/blog-left-sidebar", component: BlogLeftSidebarPage},
    {path: "/blog-right-sidebar", component: BlogRightSidebarPage},
    {path: "/blog-details/:id", component: BlogDetailsPage, props: true},
    {path: "/home-umbraco", component: umbraco},
    // { path: "/image", component: Image },
    {path: "/contact", component: ContactPage},
];

const router = createRouter({
    history: createWebHistory(),
    linkExactActiveClass: "active",
    routes,
    scrollBehavior() {
        return {top: 0};
    },
});

export default router;
