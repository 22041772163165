<template>
    <div class="security-area pb-70">
        <div class="container">
            <div class="section-title">
                <h2>Complete Website Security</h2>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloribus quam neque quibusdam corrupti aspernatur corporis alias nisi dolorum expedita veritatis voluptates minima sapiente.</p>
            </div>

            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="single-security">
                        <i class="flaticon-bug"></i>
                        <h3>Malware Detection Removal</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-security">
                        <i class="flaticon-content"></i>
                        <h3>Content Delivery Network</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-security">
                        <i class="flaticon-support"></i>
                        <h3>24/7 Cyber Security Support</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-security">
                        <i class="flaticon-profile"></i>
                        <h3>Managed Web Application</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Security'
}
</script>