<template>
    <div>
        <Navbar />
        <PageTitle />
        <BlogRightSidebar />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../BlogRightSidebar/PageTitle'
import BlogRightSidebar from '../BlogRightSidebar/BlogRightSidebar'
import FooterTop from '../Layout/FooterTop'
import FooterBottom from '../Layout/FooterBottom'

export default {
    name: 'BlogRightSidebarPage',
    components: {
        Navbar,
        PageTitle,
        BlogRightSidebar,
        FooterTop,
        FooterBottom,
    }
}
</script>