<template>
    <div>
        <Navbar />
        <PageTitle />
        <Cybersecurity />
        <Complete />
        <Electronic />
        <Approach />
        <Client />
        <Partner />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../About/PageTitle'
import Cybersecurity from '../About/Cybersecurity'
import Complete from '../About/Complete'
import Electronic from '../About/Electronic'
import Approach from '../About/Approach'
import Client from '../Common/Client'
import Partner from '../Common/Partner'
import FooterTop from '../Layout/FooterTop'
import FooterBottom from '../Layout/FooterBottom'

export default {
    name: 'AboutUsPage',
    components: {
        Navbar,
        PageTitle,
        Cybersecurity,
        Complete,
        Electronic,
        Approach,
        Client,
        Partner,
        FooterTop,
        FooterBottom,
    }
}
</script>
