<template>
  <div class="solutions-area pb-70">
    <div class="container">
      <div class="section-title">
        <h2>Son Yayınlanan Yazılar</h2>
        <p>Shop&Mag'da yayınlanan en güncel içerikler burada...</p>
      </div>

      <div class="row">
        <div class="col-lg-4" v-for="(photo, index) in Posts" :key="index">
          <div
              :style="{ backgroundImage: `url(https://backend.shopandmag.com.tr${photo.content.properties.imgCard[0]?.url || ''})` }"
              class="single-solutions"
          >
            <div class="solutions-content">

              <h3>{{ photo.content.properties.title }}</h3>

              <p>{{ photo.content.properties.assume || '' }}</p>
              <router-link :to="{ path: '/blog-details/' + photo.content.properties.pageId }" class="read-more">
                Devamını Okuyun
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import UmbracoService from '../../services/umbraco.service.js';

export default {
  name: "Solutions",
  props: {

    photo: {
      type: Object,
      required: true
    },

  },
  data() {
    return {
      Posts: null ,
    };
  },
  created() {
    UmbracoService.getContentItem()
        .then(data => {
          this.Umb = data;
          console.log("Content fetched successfully:", this.Umb);
          this.Posts = this.Umb.items[0].properties.data.items

          console.log("Filtered title:", this.Posts);
        })
        .catch(error => {
          console.error('Error fetching content:', error);
        });




  },

};
</script>

<style scoped>
.card_img:hover {
  opacity: 50%;
}
</style>
