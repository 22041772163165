<template>
    <div>
        <div class="banner-area banner-item-bg-1">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-9">
                        <div class="banner-text">
                            <span>All Research up to Blockchain Interoperability is completed</span>
                            <h1>Modern Information Protect from Hackers</h1>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil architecto laborum eaque! Deserunt maxime, minus quas molestiae reiciendis esse natus nisi iure.</p>

                            <div class="banner-btn">
                                <router-link to="/pricing" class="default-btn">
                                    Booking Now
                                </router-link>

                                <router-link to="/about-us" class="default-btn active">
                                    About Us
                                </router-link>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-3">
                        <div class="video-btn-animat">
                            <div
                                class="video-btn popup-youtube"
                                v-on:click="isPopupMethod(isPopup)"
                                style="cursor: pointer"
                            >
                                <i class="bx bx-play"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container pt-100">
                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <div class="single-features">
                            <h3><i class="bx bx-check-shield"></i> Security</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                            <span class="bx bx-check-shield"></span>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="single-features">
                            <h3><i class="bx bx-lock"></i> Privacy</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                            <span class="bx bx-lock"></span>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                        <div class="single-features mb-0">
                            <h3><i class="bx bx-certification"></i> Industry Certified</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                            <span class="bx bx-certification"></span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="lines">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>
        </div>
        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/3F9s6skyeCU"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Banner',
    data (){
        return {
            isPopup: false,
        }
    },
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
    },
}
</script>