<template>
    <div class="new-main-banner-area-wrap">
        <div class="new-main-banner-wrap-item">
            <div class="container-fluid">
                <div class="new-main-banner-wrap-content">
                    <h1>Protect Your Data In A More Effective Way Now</h1>
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit adipiscing eledictum potenti mattis viverra eget quam lacus enim porttitor bibendum elit dui nisl aenean turpis pretium.</p>

                    <ul class="banner-btn">
                        <li>
                            <router-link class="default-btn" to="/contact">Request Free Access</router-link>
                        </li>
                        <li>
                            <router-link class="default-btn color-two" to="/contact">Get Started Now</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Banner'
}
</script>