<template>
    <div>
        <Navbar />
        <PageTitle />
        <Faq />
        <Contact />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Faq/PageTitle'
import Faq from '../Faq/Faq'
import Contact from '../Faq/Contact'
import FooterTop from '../Layout/FooterTop'
import FooterBottom from '../Layout/FooterBottom'

export default {
    name: 'FaqPage',
    components: {
        Navbar,
        PageTitle,
        Faq,
        Contact,
        FooterTop,
        FooterBottom,
    }
}
</script>
