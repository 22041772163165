<template>
    <div>
        <Navbar />
        <Banner />
        <Partner />
        <Security />
        <Approach />
        <Solutions />
        <Electronic />
        <Complete />
        <Client />
        <Cybersecurity />
        <Blog />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import Banner from '../HomeOne/Banner'
import Partner from '../Common/Partner'
import Security from '../HomeOne/Security'
import Approach from '../HomeOne/Approach'
import Solutions from '../HomeOne/Solutions'
import Electronic from '../HomeOne/Electronic'
import Complete from '../HomeOne/Complete'
import Client from '../Common/Client'
import Cybersecurity from '../HomeOne/Cybersecurity'
import Blog from '../Common/Blog'
import FooterTop from '../Layout/FooterTop'
import FooterBottom from '../Layout/FooterBottom'

export default {
    name: 'HomePageOne',
    components: {
        Navbar,
        Banner,
        Partner,
        Security,
        Approach,
        Solutions,
        Electronic,
        Complete,
        Client,
        Cybersecurity,
        Blog,
        FooterTop,
        FooterBottom,
    }
}
</script>
