<template>
    <div>
        <Navbar />
        <PageTitle />
        <ServicesDetails />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../ServicesDetails/PageTitle'
import ServicesDetails from '../ServicesDetails/ServicesDetails'
import FooterTop from '../Layout/FooterTop'
import FooterBottom from '../Layout/FooterBottom'

export default {
    name: 'ServicesDetailsPage',
    components: {
        Navbar,
        PageTitle,
        ServicesDetails,
        FooterTop,
        FooterBottom,
    }
}
</script>
