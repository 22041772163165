<template>
    <div>
        <Navbar />
        <PageTitle />
        <RecoverPassword />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../RecoverPassword/PageTitle'
import RecoverPassword from '../RecoverPassword/RecoverPassword'
import FooterTop from '../Layout/FooterTop'
import FooterBottom from '../Layout/FooterBottom'

export default {
    name: 'RecoverPasswordPage',
    components: {
        Navbar,
        PageTitle,
        RecoverPassword,
        FooterTop,
        FooterBottom,
    }
}
</script>
