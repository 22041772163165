<template>
    <div class="new-main-banner-area-with-black-color">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="new-main-banner-black-content">
                        <h1>We Help To Protect Modern Information From Hackers</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Adipiscing eleifend dictum potenti mattis viverra eget quam lacus enim porttitor bibendum elit, dui nisl. aenean turpis ut pretium et dictum dolor quis consect etur feugiat odio luctus a nibh sed sit fames ac at tincidunt quam vita.</p>
    
                        <ul class="banner-btn">
                            <li>
                                <router-link class="default-btn" to="/contact">Request Free Access</router-link>
                            </li>
                            <li>
                                <router-link class="default-btn color-two" to="/contact">Get Started Now</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="new-main-banner-black-image">
                        <img src="../../assets/images/more-home/banner/black-banner.png" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="new-main-banner-black-shape">
            <img src="../../assets/images/more-home/banner/line-bg.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Banner'
}
</script>