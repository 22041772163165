<template>
  <div>
    <Navbar/>
    <div v-if="Posts != null  ">
      <PageTitle :PostsData="Posts"/>
      <BlogDetails :PostsData="Posts"/>
    </div>
    <FooterTop/>
    <FooterBottom/>
  </div>
</template>

<script>
import Navbar from '../Layout/Navbar';
import PageTitle from '../BlogDetails/PageTitle';
import BlogDetails from '../BlogDetails/BlogDetails';
import FooterTop from '../Layout/FooterTop';
import FooterBottom from '../Layout/FooterBottom';

import UmbracoService from '../../services/umbraco.service.js';

export default {
  name: 'BlogDetailsPage',
  components: {
    Navbar,
    PageTitle,
    BlogDetails,
    FooterTop,
    FooterBottom,
  },
  data() {
    return {
      Umb: null,
      Posts: null ,

    };
  },
  created() {

    UmbracoService.getContentItem()

        .then(data => {
          this.Umb = data;
          let temparray = this.Umb.items[0].properties.data.items;
          console.log("Content fetched successfully:", this.Umb);
          const pageId = parseInt(this.$route.params.id, 10);

          this.Posts = temparray.find(item => item.content.properties.pageId === pageId);
          console.log("Posts:",this.Posts );
        })
        .catch(error => {
          console.error('Error fetching content:', error);

        });
  }
}
</script>
