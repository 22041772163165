<template>
    <div class="loader-wrapper">
        <div class="loader"></div>
        <div class="loader-section section-left"></div>
        <div class="loader-section section-right"></div>
    </div>
</template>

<script>
export default {
    name: 'Preloader'
}
</script>