<template>
    <div class="counter-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="single-counter">
                        <i class="flaticon-anti-virus-software"></i>
                        <h2>704+</h2>
                        <p>Clients Protection</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-counter">
                        <i class="flaticon-cyber"></i>
                        <h2>200+</h2>
                        <p>Smart Home Protection</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-counter">
                        <i class="flaticon-content"></i>
                        <h2>800+</h2>
                        <p>Website Protection</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-counter">
                        <i class="flaticon-medal"></i>
                        <h2>145+</h2>
                        <p>Programmers Team</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Counter'
}
</script>