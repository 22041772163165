<template>
    <div class="choose-area ptb-100">
        <div class="container">
            <div class="choose-wrap">
                <h2>Why Choose Us</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                <ul>
                    <li>
                        <i class="bx bx-check"></i>
                        Extemly low response  time at all time
                    </li>
                    <li>
                        <i class="bx bx-check"></i>
                        We are always ready for your growth
                    </li>
                    <li>
                        <i class="bx bx-check"></i>
                        We understand security and compliance
                    </li>
                </ul>

                <a href="#" class="default-btn mt-30">
                    Know Details
                </a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Choose'
}
</script>