<template>
    <div class="success-projects-area pt-100 pb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <div class="success-projects-section-content">
                        <h3>See Our Numerous Success Project From Previous</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit adipiscing eleifend dictum potenti mattis viverra eget quam lacus enim porttitor bibendum elit dui nisl.</p>
                        <a href="#" class="default-btn">View All Project</a>
                    </div>
                </div>

                <div class="col-lg-8 col-md-12">
                    <div class="success-projects-slides">
                        <carousel 
                            :autoplay="5000"
                            :settings="settings"
                            :breakpoints="breakpoints"
                        >
                            <slide 
                                v-for="slide in carouselItems" 
                                :key="slide.id"
                            >
                                <div class="single-success-projects-card">
                                    <div class="projects-image">
                                        <router-link :to="slide.link">
                                            <img :src="slide.image" alt="image">
                                        </router-link>

                                        <div class="icon">
                                            <router-link :to="slide.link">
                                                <i class='bx bx-right-arrow-alt'></i>
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="projects-content">
                                        <h3>
                                            <router-link :to="slide.link">{{slide.title}}</router-link>
                                        </h3>
                                    </div>
                                </div>
                            </slide>

                            <template #addons>
                                <Pagination />
                            </template>
                        </carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'SuccessProjects',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/more-home/projects/projects-1.jpg'),
                title: 'Confidential Information',
                link: '/services-details',
            },
            {
                id: 2,
                image: require('../../assets/images/more-home/projects/projects-2.jpg'),
                title: 'Incident Responder',
                link: '/services-details',
            },
            {
                id: 3,
                image: require('../../assets/images/more-home/projects/projects-3.jpg'),
                title: 'Secure Managed IT',
                link: '/services-details',
            },
            {
                id: 4,
                image: require('../../assets/images/more-home/projects/projects-1.jpg'),
                title: 'Confidential Information',
                link: '/services-details',
            },
            {
                id: 5,
                image: require('../../assets/images/more-home/projects/projects-2.jpg'),
                title: 'Incident Responder',
                link: '/services-details',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
        },
    }),
})
</script>