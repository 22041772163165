<template>
    <div>
        <NavbarStyleTwo />
        <Banner />
        <Features />
        <Security />
        <Transform />
        <Performance />
        <Team />
        <ManualArea />
        <Faq />
        <Choose />
        <Cybersecurity />
        <BlogStyleTwo />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import Banner from '../HomeThree/Banner'
import Features from '../HomeThree/Features'
import Security from '../HomeThree/Security'
import Transform from '../HomeThree/Transform'
import Performance from '../HomeThree/Performance'
import Team from '../Common/Team'
import ManualArea from '../HomeThree/ManualArea'
import Faq from '../HomeThree/Faq'
import Choose from '../HomeThree/Choose'
import Cybersecurity from '../HomeThree/Cybersecurity'
import BlogStyleTwo from '../Common/BlogStyleTwo'
import FooterTop from '../Layout/FooterTop'
import FooterBottom from '../Layout/FooterBottom'

export default {
    name: 'HomePageThree',
    components: {
        NavbarStyleTwo,
        Banner,
        Features,
        Security,
        Transform,
        Performance,
        Team,
        ManualArea,
        Faq,
        Choose,
        Cybersecurity,
        BlogStyleTwo,
        FooterTop,
        FooterBottom,
    }
}
</script>
