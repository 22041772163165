<template>
    <div>
        <Navbar />
        <Banner />
        <Partner />
        <Performance/>
        <Transform />
        <Pricing />
        <Choose />
        <Team />
        <Solutions />
        <Graph />
        <Faq />
        <BlogStyleTwo />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import Banner from '../HomeFour/Banner'
import Partner from '../Common/Partner'
import Performance from '../HomeFour/Performance'
import Transform from '../HomeFour/Transform'
import Pricing from '../Common/Pricing'
import Choose from '../HomeFour/Choose'
import Team from '../Common/Team'
import Solutions from '../HomeFour/Solutions'
import Graph from '../HomeFour/Graph'
import Faq from '../HomeFour/Faq'
import BlogStyleTwo from '../Common/BlogStyleTwo'
import FooterTop from '../Layout/FooterTop'
import FooterBottom from '../Layout/FooterBottom'

export default {
    name: 'HomePageFour',
    components: {
        Navbar,
        Banner,
        Partner,
        Performance,
        Transform,
        Pricing,
        Choose,
        Team,
        Solutions,
        Graph,
        Faq,
        BlogStyleTwo,
        FooterTop,
        FooterBottom,
    }
}
</script>
