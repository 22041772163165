<template>
    <div class="home-control-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="home-control-content">
                        <h3>Innovative Electronic Protection Of Your Office And Home Control</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit adipiscing eleifend dictum potenti mattis viverra eget quam lacus enim porttitor bibendum elit dui nisl.</p>

                        <div class="home-control-tab-wrap">
                            <div class="home-control-tab">
                                <div class="tabs">
                                    <ul class="tabs__header">
                                        <li 
                                            class="tabs__header-item" 
                                            v-for="tab in tabs" 
                                            v-on:click="selectTab (tab.id)" 
                                            v-bind:class="{ 'active': activeTab == tab.id }"
                                            :key="tab.id"
                                        >
                                            {{tab.title}}
                                        </li>
                                    </ul>
                                </div>
                                <div class="tabs__container">
                                    <div 
                                        class="tabs__list"
                                        ref='tabsList'
                                    >
                                        <div 
                                            class="tabs__list-tab"
                                            v-for="tab in tabs"
                                            v-bind:class="{ 'active': activeTab == tab.id }"
                                            :key="tab.id"
                                        >
                                            <p>{{tab.description}}</p>
                                            <div class="row justify-content-center">
                                                <div 
                                                    class="col-lg-6 col-md-6"
                                                    v-for="item in tab.listsItems"
                                                    :key="item.id"
                                                >
                                                    <ul class="list">
                                                        <li
                                                            v-for="list in item.lists"
                                                            :key="list.id"
                                                        >
                                                            <i :class='list.icon'></i> 
                                                            {{list.text}}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <router-link class="default-btn" :to="tab.btnLink">{{tab.btnText}}</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="home-control-image">
                        <img src="../../assets/images/more-home/home-control/home-control.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ElectronicProtection',
    data () {
        return {
            activeTab: 1,
            offsetRight: 0,
            tabs: [
                {
                    id: 1,
                    title: 'Intercom System',
                    description: 'Lorem ipsum dolor sit amet consecte adipi scing elit. Adipiscing eleifend poteder mattis viverra lorem ipsum dolor sit amet consecte adipisci ng elit adipiscingdiper eleifend dictum potenti mattis viverra.',
                    listsItems: [
                        {
                            id: 1,
                            lists: [
                                {
                                    id: 1,
                                    icon: 'bx bx-check',
                                    text: 'Focus on The Basics',
                                },
                                {
                                    id: 23,
                                    icon: 'bx bx-check',
                                    text: 'Educate Customers',
                                },
                            ]
                        },
                        {
                            id: 2,
                            lists: [
                                {
                                    id: 1,
                                    icon: 'bx bx-check',
                                    text: 'Be Proactive',
                                },
                                {
                                    id: 23,
                                    icon: 'bx bx-check',
                                    text: 'Tighten Internal Controls',
                                },
                            ]
                        }
                    ],
                    btnText: 'Learn About',
                    btnLink: '/about-us'
                },
                {
                    id: 2,
                    title: 'CCTV',
                    description: 'Lorem ipsum dolor sit amet consecte adipi scing elit. Adipiscing eleifend poteder mattis viverra lorem ipsum dolor sit amet consecte adipisci ng elit adipiscingdiper eleifend dictum potenti mattis viverra.',
                    listsItems: [
                        {
                            id: 1,
                            lists: [
                                {
                                    id: 1,
                                    icon: 'bx bx-check',
                                    text: 'Focus on The Basics',
                                },
                                {
                                    id: 23,
                                    icon: 'bx bx-check',
                                    text: 'Educate Customers',
                                },
                            ]
                        },
                        {
                            id: 2,
                            lists: [
                                {
                                    id: 1,
                                    icon: 'bx bx-check',
                                    text: 'Be Proactive',
                                },
                                {
                                    id: 23,
                                    icon: 'bx bx-check',
                                    text: 'Tighten Internal Controls',
                                },
                            ]
                        }
                    ],
                    btnText: 'Learn About',
                    btnLink: '/about-us'
                },
                {
                    id: 3,
                    title: 'GDPR',
                    description: 'Lorem ipsum dolor sit amet consecte adipi scing elit. Adipiscing eleifend poteder mattis viverra lorem ipsum dolor sit amet consecte adipisci ng elit adipiscingdiper eleifend dictum potenti mattis viverra.',
                    listsItems: [
                        {
                            id: 1,
                            lists: [
                                {
                                    id: 1,
                                    icon: 'bx bx-check',
                                    text: 'Focus on The Basics',
                                },
                                {
                                    id: 23,
                                    icon: 'bx bx-check',
                                    text: 'Educate Customers',
                                },
                            ]
                        },
                        {
                            id: 2,
                            lists: [
                                {
                                    id: 1,
                                    icon: 'bx bx-check',
                                    text: 'Be Proactive',
                                },
                                {
                                    id: 23,
                                    icon: 'bx bx-check',
                                    text: 'Tighten Internal Controls',
                                },
                            ]
                        }
                    ],
                    btnText: 'Learn About',
                    btnLink: '/about-us'
                },
                {
                    id: 4,
                    title: 'Encryption',
                    description: 'Lorem ipsum dolor sit amet consecte adipi scing elit. Adipiscing eleifend poteder mattis viverra lorem ipsum dolor sit amet consecte adipisci ng elit adipiscingdiper eleifend dictum potenti mattis viverra.',
                    listsItems: [
                        {
                            id: 1,
                            lists: [
                                {
                                    id: 1,
                                    icon: 'bx bx-check',
                                    text: 'Focus on The Basics',
                                },
                                {
                                    id: 23,
                                    icon: 'bx bx-check',
                                    text: 'Educate Customers',
                                },
                            ]
                        },
                        {
                            id: 2,
                            lists: [
                                {
                                    id: 1,
                                    icon: 'bx bx-check',
                                    text: 'Be Proactive',
                                },
                                {
                                    id: 23,
                                    icon: 'bx bx-check',
                                    text: 'Tighten Internal Controls',
                                },
                            ]
                        }
                    ],
                    btnText: 'Learn About',
                    btnLink: '/about-us'
                },
                {
                    id: 5,
                    title: 'Our Goal',
                    description: 'Lorem ipsum dolor sit amet consecte adipi scing elit. Adipiscing eleifend poteder mattis viverra lorem ipsum dolor sit amet consecte adipisci ng elit adipiscingdiper eleifend dictum potenti mattis viverra.',
                    listsItems: [
                        {
                            id: 1,
                            lists: [
                                {
                                    id: 1,
                                    icon: 'bx bx-check',
                                    text: 'Focus on The Basics',
                                },
                                {
                                    id: 23,
                                    icon: 'bx bx-check',
                                    text: 'Educate Customers',
                                },
                            ]
                        },
                        {
                            id: 2,
                            lists: [
                                {
                                    id: 1,
                                    icon: 'bx bx-check',
                                    text: 'Be Proactive',
                                },
                                {
                                    id: 23,
                                    icon: 'bx bx-check',
                                    text: 'Tighten Internal Controls',
                                },
                            ]
                        }
                    ],
                    btnText: 'Learn About',
                    btnLink: '/about-us'
                },
            ]
        }
    },
    methods: {
        selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }
    },
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    },
}
</script>