<template>
  <div class="blog-area ptb-100">
    <div class="container">
      <!-- <div class="section-title">
          <h2>{{ PostsData[0].category }}</h2>
          <p>{{ PostsData[0].category }} konusuna dair son yazılar burada!</p>
      </div> -->
      <div class="row">
        <div class="col-lg-4 col-sm-6" v-for="(data , index) in PostsData" :key="index">
          <div class="single-blog">
            <img
                :src="require(`@/assets/images/${data.imgCard}`)"
                class="card_img"
                alt="Image">
            <div class="blog-content">
              <h3>
                <router-link :to="{ path: '/blog-details/' + data._id }">{{ data.title }}</router-link>
              </h3>
              <p v-html="data.assume"></p>
              <router-link :to="{ path: '/blog-details/' + data._id }" class="read-more">
                Devamını Okuyun
              </router-link>
            </div>
          </div>
        </div>


        <!--

                            <div class="page-navigation-area">
                                <nav aria-label="Page navigation example text-center">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link page-links" href="#">
                                                <i class='bx bx-chevrons-left'></i>
                                            </a>
                                        </li>
                                        <li class="page-item active">
                                            <a class="page-link" href="#">1</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">2</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">3</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link" href="#">
                                                <i class='bx bx-chevrons-right'></i>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>

                        </div> -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BlogArea',
  props: ["PostsData"],

}
</script>

<style scoped>

</style>