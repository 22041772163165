<template>
    <div class="performance-area ptb-100">
        <div class="container">
            <div class="section-title white-title">
                <h2>High-Performance Solutions</h2>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloribus quam neque quibusdam corrupti aspernatur corporis alias nisi dolorum expedita veritatis voluptates minima.</p>
            </div>

            <div class="single-security-wrap">
                <carousel 
                    :autoplay="5000"
                    :settings="settings"
                    :breakpoints="breakpoints"
                >
                    <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div class="single-security">
                            <i :class="slide.icon"></i>
                            <h3>{{slide.title}}</h3>
                            <p>{{slide.description}}</p>

                            <router-link :to="slide.btnLink" class="read-more">
                                {{slide.btnText}}
                            </router-link>
                        </div>
                    </slide>

                    <template #addons>
                        <Pagination />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Performance',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'left',
        },
        carouselItems: [
            {
                id: 1,
                icon: 'flaticon-website',
                title: 'Confidential Information',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
                btnText: 'Read More',
                btnLink: '/contact',
            },
            {
                id: 2,
                icon: 'flaticon-profile',
                title: 'Mobile Security',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
                btnText: 'Read More',
                btnLink: '/contact',
            },
            {
                id: 3,
                icon: 'flaticon-content',
                title: 'Website Protection',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
                btnText: 'Read More',
                btnLink: '/contact',
            },
            {
                id: 4,
                icon: 'flaticon-cyber',
                title: 'Hacking Protection',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
                btnText: 'Read More',
                btnLink: '/contact',
            },
            {
                id: 5,
                icon: 'flaticon-profile',
                title: 'Mobile Security',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
                btnText: 'Read More',
                btnLink: '/contact',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
        },
    }),
})
</script>