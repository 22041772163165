<template>
    <div class="new-main-banner-area">
        <div class="new-main-home-slides">
            <carousel 
                :autoplay="12000"
                :settings="settings"
                :wrap-around="true"
            >
                <slide 
                    v-for="slide in carouselItems" 
                    :key="slide.id"
                >
                    <div class="new-main-banner-slides-item">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-5 col-md-12">
                                    <div class="new-main-banner-content">
                                        <span>{{slide.subTitle}}</span>
                                        <h1>{{slide.heading}}</h1>
                                        <p>{{slide.description}}</p>
            
                                        <ul class="banner-btn">
                                            <li>
                                                <router-link class="default-btn" to="/contact">Request Free Access</router-link>
                                            </li>
                                            <li>
                                                <router-link class="default-btn color-two" to="/contact">Get Started Now</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                
                                <div class="col-lg-7 col-md-12">
                                    <div :class="slide.class"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </slide>

                <template #addons>
                    <Navigation />
                </template>
            </carousel>
        </div>

        <div class="new-main-banner-shape">
            <img src="../../assets/images/more-home/banner/shape.png" alt="image">
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Banner',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                class: 'new-main-banner-image',
                subTitle: 'All Research Up to Blockchain Interoperability is Completed',
                heading: 'Protect Your data In A More Effective Way',
                description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit adipiscing eleifend dictum potenti mattis viverra eget quam lacus enim porttitor bibendum elit dui nisl. aenean turpis ut pretium et dictum dolor quis consect etur feugiat odio luctus a nibh sed sit fames ac at tincidunt quam vita.',
            },
            {
                id: 2,
                class: 'new-main-banner-image image-two',
                subTitle: 'All Research Up to Blockchain Interoperability is Completed',
                heading: 'We Help To Protect Modern Information From Hackers',
                description: 'Lorem ipsum dolor sit amet consectetur adipiscing elit adipiscing eleifend dictum potenti mattis viverra eget quam lacus enim porttitor bibendum elit dui nisl. aenean turpis ut pretium et dictum dolor quis consect etur feugiat odio luctus a nibh sed sit fames ac at tincidunt quam vita.',
            },
        ],
    }),
})
</script>