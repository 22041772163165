<template>
    <div class="expert-team-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="expert-team-content">
                        <h3>Meet Our Expert Team Who are Behind The Whole Security Management</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit adipiscing eleifend dictum potenti mattis viverra eget quam lacus enim porttitor bibendum elit dui nisl.</p>
                        <router-link to="/team" class="default-btn">View All Team Members</router-link>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="expert-team-image">
                        <img src="../../assets/images/more-home/expert-team/expert-team.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TeamStyleOne'
}
</script>