<template>
    <div class="faq-area ptb-100">
        <div class="container">
            <div class="section-title">
                <h2>Frequently Asked  Questions</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque blanditiis exercitationem incidunt, alias corporis quam assumenda dicta.</p>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="faq-img-four">
                        <img src="../../assets/images/faq-img.jpg" alt="Image">
                    </div>
                </div>

                <div class="col-lg-6">
                    <accordion>
                        <accordion-item>
                            <template v-slot:accordion-trigger>
                                <button class="accordion-title">
                                    What's The Difference Between IDS And IBS?
                                    <i class='bx bx-plus'></i>
                                </button>
                            </template>
                            <template v-slot:accordion-content>
                                <p>Lorem, ipsum dolor sit amet How do you Startup? consectetur adipisicing elit. Accusamus ipsa error, excepturi, obcaecati aliquid veniam blanditiis quas voluptates maxime unde, iste minima dolores dolor perferendis facilis. How do you Startup blanditiis voluptates Lorem, ipsum dolor sit amet How do you.</p>
                            </template>
                        </accordion-item>
                        <accordion-item>
                            <template v-slot:accordion-trigger>
                                <button class="accordion-title">
                                    How Is Encryption Different From Hacking
                                    <i class='bx bx-plus'></i>
                                </button>
                            </template>
                            <template v-slot:accordion-content>
                                <p>Lorem, ipsum dolor sit amet How do you Startup? consectetur adipisicing elit. Accusamus ipsa error, excepturi, obcaecati aliquid veniam blanditiis quas voluptates maxime unde, iste minima dolores dolor perferendis facilis. How do you Startup blanditiis voluptates Lorem, ipsum dolor sit amet How do you.</p>
                            </template>
                        </accordion-item>
                        <accordion-item>
                            <template v-slot:accordion-trigger>
                                <button class="accordion-title">
                                    What Is Firewall And Why It Is Used?
                                    <span></span>
                                    <i class='bx bx-plus'></i>
                                </button>
                            </template>
                            <template v-slot:accordion-content>
                                <p>Lorem, ipsum dolor sit amet How do you Startup? consectetur adipisicing elit. Accusamus ipsa error, excepturi, obcaecati aliquid veniam blanditiis quas voluptates maxime unde, iste minima dolores dolor perferendis facilis. How do you Startup blanditiis voluptates Lorem, ipsum dolor sit amet How do you.</p>
                            </template>
                        </accordion-item>
                        <accordion-item>
                            <template v-slot:accordion-trigger>
                                <button class="accordion-title">
                                    What Steps Will You Take To Secure server
                                    <i class='bx bx-plus'></i>
                                </button>
                            </template>
                            <template v-slot:accordion-content>
                                <p>Lorem, ipsum dolor sit amet How do you Startup? consectetur adipisicing elit. Accusamus ipsa error, excepturi, obcaecati aliquid veniam blanditiis quas voluptates maxime unde, iste minima dolores dolor perferendis facilis. How do you Startup blanditiis voluptates Lorem, ipsum dolor sit amet How do you.</p>
                            </template>
                        </accordion-item>
                    </accordion>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";

export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>