<template>
  <div class="blog-details-area ptb-100">
    <div class="container">
      <!-- <img src="../../assets/images/approach-img.jpg" alt=""> -->
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="blog-details-desc">
            <div class="article-content">

              <div>

              <ul>
                <li>
                  <span style="margin-right: 10px">Yayınlanma Tarihi:</span>
                  <a href="#">{{ content.date }}</a>
                </li>
                <div class="hareket" >
                  <s-whats-app :share-options="WhatsappShareOptions" :use-native-behavior="useNativeBehavior"
                               :window-features="windowFeatures" style="text-decoration:none" @popup-close="onClose"
                               @popup-open="onOpen" @popup-block="onBlock" @popup-focus="onFocus">
                    <svg class="bi bi-whatsapp" fill="#ca1104" height="24" viewBox="0 0 16 16"
                         width="24"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                    </svg>
                  </s-whats-app>

                  <s-facebook :share-options="facebookShareOptions" :use-native-behavior="useNativeBehavior"
                              :window-features="windowFeatures" style="text-decoration:none" @popup-close="onClose"
                              @popup-open="onOpen" @popup-block="onBlock" @popup-focus="onFocus">
                    <svg aria-hidden="true" fill="#ca1104" focusable="false" height="24" viewBox="0 0 24 24"
                         width="24" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/>
                    </svg>
                  </s-facebook>

                  <s-twitter :share-options="twitterShareOptions" :use-native-behavior="useNativeBehavior"
                             :window-features="windowFeatures" style="text-decoration:none" @popup-close="onClose"
                             @popup-open="onOpen" @popup-block="onBlock" @popup-focus="onFocus">
                    <svg aria-hidden="true" fill="#ca1104" focusable="false" height="24" viewBox="0 0 24 24"
                         width="24" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/>
                    </svg>
                  </s-twitter>




                </div>
                <a style="margin-left: -5px  " href="https://www.instagram.com/shopandmag" target="_blank">
                  <i class="bx bxl-instagram bx-md"></i>
                </a>
              </ul>
            </div>
            <h3>{{ content.title }}</h3>

            <div class="article-image">

              <div v-if="content.photos ">
                <Splide :options="{ rewind: true }">
                  <SplideSlide v-for="(photo, index) in content.photos" :key="index">
                    <div v-for="(data, index) in photo.content.properties.popUp.items" :key="index"
                         :class="{ 'hovered': isHovered }"
                         :style="{ left: data.content.properties.positionLeft + '%', top: data.content.properties.positionTop + '%' }"
                         class="circle_inf" @click="openLink(data.content.properties.linkHref[0].url)">
                      <img :class="{ 'hovered-image': isHovered }" :src="require('@/assets/images/link/Star.png')">
                      <a :class="{ 'hovered': isHovered }" :href="data.content.properties.linkHref[0].url"
                         target="_blank">{{ data.content.properties.title }}</a>
                    </div>
                    <div class="responsive-image">

                      <img :src="`https://backend.shopandmag.com.tr/${photo.content.properties.img[0].url}`"
                           alt="Photo" height="865" usemap="#shopmap" width="1296"/>
                    </div>
                  </SplideSlide>
                </Splide>
                <img :src="toggleButtonImageSource" :style="{ left: 90 + '%', bottom: 95 + '%' }"
                     class="linkstar-image"
                     @click="groupFunction()"/>
                <blockquote class="flaticon-content">
                  <p v-html="content.assume"></p>
                </blockquote>
              </div>
            </div>

            <div v-for="(list, listindex) in content.list" :key="listindex">
              <div class="responsive-image" style="position: relative;">
                <img :src="`https://backend.shopandmag.com.tr/${list.content.properties.img[0].url}`" alt="Photo"
                     height="865" usemap="#shopmap" width="1296"/>
                <div v-for="(data, list2index) in list.content.properties.popUp.items" :key="list2index"
                     :alt="data.title"
                     :class="{ 'hovered': isHovered }" :src="require(`@/assets/images/link/Star.png`)"
                     :style="{ left: data.content.properties.positionLeft + '%', top: data.content.properties.positionTop + '%' }"
                     class="circle_inf" @click="openLink(data.content.properties.linkHref[0].url)">
                  <img :alt="data.content.properties.title" :class="{ 'hovered-image': isHovered }"
                       :src="require('@/assets/images/link/Star.png')">
                  <a :href="data.content.properties.linkHref[0].url" class="circle_infd"
                     target="_blank">{{ data.content.properties.title }}</a>
                </div>
                <img :src="toggleButtonImageSource" :style="{ left: 90 + '%', bottom: 95 + '%' }"
                     class="linkstar-image" @click="groupFunction()"/>
                <blockquote class="flaticon-quote">
                  <p v-html="list.content.properties.articlefree"></p>
                </blockquote>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div v-html="content.article"></div>
    <br/><br/>

    <h3 class="related-posts">İlgili İçerikler</h3>
    <div class="mt-5">
      <Solutions/>
    </div>
  </div>
  </div>


</template>


<script>
import {defineComponent} from "vue";
import Solutions from "./Content.vue";

import {Splide, SplideSlide} from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';

import UmbracoService from '../../services/umbraco.service.js';

import Posts from "../../assets/data/Posts.json";
import TransformVue from "../HomeFive/Transform.vue";
import {SFacebook, STwitter, SWhatsApp} from 'vue-socials'


export default defineComponent({
  name: "BlogDetails",
  props: {
    PostsData: {
      type: Array,
      required: true
    },
    photo: {
      type: Object,
      required: true
    }
  },


  components: {
    Solutions,
    UmbracoService,
    Splide,
    SplideSlide,
    SFacebook, STwitter, SWhatsApp
    // BlogSidebar,
  },
  data() {
    return {

      Posts: Posts.properties,
      content: null,
      Umb: [],
      active: false,
      photos: [],
      list: [],
      toggleButtonImageSource: require('@/assets/images/link/Star_pin_off.png'),
      active2: false,

      clicked: false,
      clicked2: false,
      mouseposX: 0,
      mouseposY: 0,
      display1: false,
      display2: false,
      ceketId: 1,
      compId: 2,
      popupCoordsX: null,
      popupCoordsY: null,
      popupCoordsX2: null,
      popupCoordsY2: null,
      isHovered: false,

      relatedPosts: null,
      windowFeatures: {},
      facebookShareOptions: {
        url: 'https://zzz.shopandmag.com.tr',
        quote: 'Quote',
        hashtag: '#ShopAndMag',
      },
      twitterShareOptions: {
        url: 'https://zzz.shopandmag.com.tr',
        text: '#ShopAndMag',
        hashtags: ['hash', 'tag'],
        via: 'twitterdev',
      },
      WhatsappShareOptions: {
        url: 'https://zzz.shopandmag.com.tr',
        title: '#ShopAndMag',
        description: "This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
      },

      useNativeBehavior: false,

    };
  },

  created() {
    console.log("Filtered Posts:", this.PostsData);


    const postData = this.PostsData;
    this.content = {
      _id: postData.content.properties.pageId,
      title: postData.content.properties.title,
      date: postData.content.properties.date,
      // imgCard: postData.content.properties.imgCard[0] ? postData.content.properties.imgCard[0].url : '',
      navbar: postData.content.properties.navbar[0] ? postData.content.properties.navbar[0].url : '',

      categoryId: postData.categoryId,
      picture: postData.content.properties.picture[0] ? postData.content.properties.picture[0].url : '',
      article: postData.content.properties.article || '',
      assume: postData.content.properties.assume || '',
      article2: postData.content.properties.article2 || '',

      photos: postData.content.properties.photos && postData.content.properties.photos.items ? postData.content.properties.photos.items : null,
      list: postData.content.properties.list && postData.content.properties.list.items ? postData.content.properties.list.items : [],

    };
    console.log("Filtered title:", this.content);


  },
  computed: {
    computed: {},
  },
  mounted() {
    document.addEventListener("mousemove", event => {
      this.mousePosX = event.pageX;
      this.mousePosY = event.pageY;

    });
  },
  methods: {
    dosyaAdiphoto() {
      return this.photo.content.properties.url.substring(this.list.content.properties.url.lastIndexOf("/") + 1);
    },
    dosyaAdilist() {
      return this.list.content.properties.url.substring(this.list.content.properties.url.lastIndexOf("/") + 1);
    },


    onClose() {
    },
    onOpen() {
    },
    onBlock() {
    },
    onFocus() {
    },
    groupFunction() {
      this.isHovered = !this.isHovered;
      this.toggleButtonImageSource = this.isHovered ? require('@/assets/images/link/Star.png') : require('@/assets/images/link/Star_pin_off.png')
    },
    clickPro1() {
      this.popupCoordsCalc();
      this.clicked = true;
      this.clicked2 = false;
    },
    clickPro2() {
      this.clicked = false;
      this.clicked2 = true;
    },
    popup1_click() {
      this.active = false;
    },
    popup2_click() {
      this.active2 = false;
    },
    mouseOver() {
      this.popupCoordsCalc();
      // console.log(`${this.popupCoordsX} ${this.popupCoordsY} `)
      this.active = TransformVue;
    },
    mouseOut() {
      if (this.clicked === true) {
        this.active = true;
      } else {
        this.active = false;
      }
      this.clicked = false;
    },
    mouseOver2() {
      this.popupCoordsCalc2();
      this.active2 = true;
    },
    openLink(linkHref) {
      window.open(linkHref, '_blank');
    },
    mouseOut2() {
      if (this.clicked2 === true) {
        this.active2 = true;
      } else {
        this.active2 = false;
      }
      this.clicked2 = false;
    },
    popupCoordsCalc() {
      this.popupCoordsX = (this.PostsData[0].popUp[0].coords[2] - this.PostsData[0].popUp[0].coords[0]) / 2 + 500;
      this.popupCoordsY = (this.PostsData[0].popUp[0].coords[3] - this.PostsData[0].popUp[0].coords[1]) / 2;
    },
    popupCoordsCalc2() {
      this.popupCoordsX2 = (this.PostsData[0].popUp[1].coords[2] - this.PostsData[0].popUp[1].coords[0]) / 2 + 300;
      this.popupCoordsY2 = (this.PostsData[0].popUp[1].coords[3] - this.PostsData[0].popUp[1].coords[1]) / 2;
    },
  },
});
</script>

<style scoped>
.linkstar-image {
  height: 38px;
  /* Adjust the height and width as per your PNG image size */
  width: 50px;
  left: 50%;
  position: absolute;
  cursor: pointer;
}

@media only screen and (max-width: 992px) {
  .linkstar-image {
    height: 25px;
    width: 35px;
  }
}

@media only screen and (max-width: 768px) {
  .linkstar-image {
    height: 19px;
    width: 25px;
  }
}

/* standart hal */
.circle_inf {
  height: 25px;
  width: 25px;
  position: absolute;

  background-color: #ffffff;
  border-radius: 70%;
  cursor: pointer;
}

.circle_inf a {
  height: 25px;
  width: 250px;
  padding-left: 30px;
  color: purple;

}

/* standart hal resim */
.circle_inf img {
  height: 25px;
  width: 25px;
  position: absolute;

  background-color: #ffffff;
  border-radius: 60%;


}

/* butonsuz açılma */
.circle_inf:hover {
  height: 25px;
  width: 250px;
  background-color: #ffffff;
  border-radius: 7px 7px;
  transition: 0.3s;


}

.circle_inf {
  opacity: 1;
}

/* butonsuz açılma resim */
.circle_inf:hover img {
  height: 25px;
  width: 250px;
  background-color: #f8f8f8;
  border-radius: 7px 7px;
  transition: 0.3s;
  display: none
}

/* butonlu açılma */


.circle_inf.hovered {
  height: 25px;
  width: 250px;
  background-color: #ffffff;
  border-radius: 7px 7px;
  transition: 0.3s;
  z-index: 5;
}


/* butonlu açılma resim */
.hovered-image {
}


area {
  cursor: pointer;
}

.article-image {
  position: relative;
}

.listlink {
  position: relative
}

.finger-pic {
  position: absolute;
  width: 30px;
}

.popup_simple1 {
  position: absolute;
  display: inline-block;
}

.popup_simple2 {
  position: absolute;
  display: inline-block;
}</style>
