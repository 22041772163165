<template>
    <footer class="footer-style-two-with-color pt-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget-card">
                        <a href="index.html" class="logo d-inline-block">
                            <img src="../../assets/images/logo.png" alt="logo">
                        </a>
                        <p>Lorem ipsum dolor sit amet consectetur sed do eiusmod tempor incididunt ut labore et dolore magna aliqua minim veniam.</p>
                        <ul class="social-links">
                            <li><a href="https://www.facebook.com/" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="https://www.twitter.com/" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="https://www.instagram.com/" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="https://www.linkedin.com/" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget-card ps-5">
                        <h3>About Us</h3>
                        <ul class="custom-links">
                            <li><router-link to="/about-us">About Us</router-link></li>
                            <li><router-link to="/pricing">Pricing</router-link></li>
                            <li><router-link to="/services-details">Website Security</router-link></li>
                            <li><router-link to="/terms-conditions">Terms & Conditions</router-link></li>
                            <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                        </ul>
                    </div>
                </div>
                
                <div class="col-lg-3 col-md-4 col-sm-4">
                    <div class="single-footer-widget-card">
                        <h3>Contact Us</h3>
                        <ul class="footer-contact-info">
                            <li>
                                <span>Address: </span>
                                658 Lane Drive st Riverside. California
                            </li>
                            <li>
                                <span>Email: </span>
                                <a href="mailto:hello@quvia.com">hello@quvia.com</a>
                            </li>
                            <li>
                                <span>Phone: </span>
                                <a href="tel:+1-485-456-0102">+1-485-456-0102</a>
                            </li>
                            <li>
                                <span>Fax: </span>
                                <a href="tel:+1-07854578964">+1-0785 4578964</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-4 col-sm-4">
                    <div class="single-footer-widget-card">
                        <h3>Newsletter</h3>
                        
                        <div class="widget-newsletter-content">
                            <p>Latest resources sent to your inbox weekly</p>
                        </div>   

                        <form class="newsletter-form" data-bs-toggle="validator">
                            <input type="email" class="input-newsletter" placeholder="Email address" name="EMAIL" required autocomplete="off">
    
                            <button type="submit" class="default-btn">Subscribe Now</button>
                            <div id="validator-newsletter" class="form-result"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-style-two-with-color">
            <p>&copy; {{currentYear}} shop&mag</p>
        </div>
        <div class="footer-shape-1">
            <img src="../../assets/images/more-home/footer/footer-shape-1.png" alt="image">
        </div>
        <div class="footer-shape-2">
            <img src="../../assets/images/more-home/footer/footer-shape-2.png" alt="image">
        </div>
        <div class="lines-line">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterStyleThree',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>