<template>
    <div class="banner-area banner-area-three">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-text">
                        <span>All Research up to Blockchain Interoperability is completed</span>
                        <h1>Cyber Security Is Not Optional</h1>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil architecto laborum eaque! Deserunt maxime, minus quas molestiae reiciendis esse natus nisi iure.</p>

                        <div class="banner-btn">
                            <router-link class="default-btn" to="/pricing">
                                Booking Now
                            </router-link>
                            <router-link class="default-btn active" to="/about-us">
                                About Us
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="follow-us">
            <ul>
                <li>
                    <span>Follow Us On Facebook</span>
                </li>
                <li>
                    <a href="https://www.facebook.com/" target="_blank">
                        <i class="bx bxl-facebook"></i>
                    </a>
                </li>
                <li>
                    <a href="https://www.instagram.com/" target="_blank">
                        <i class="bx bxl-instagram"></i>
                    </a>
                </li>
                <li>
                    <a href="https://www.twitter.com/" target="_blank">
                        <i class="bx bxl-twitter"></i>
                    </a>
                </li>
            </ul>
        </div>
        
        <div class="banner-shape-1">
            <img src="../../assets/images/shape/shape-1.png" alt="Image">
        </div>
        <div class="banner-shape-2">
            <img src="../../assets/images/shape/shape-2.png" alt="Image">
        </div>
        <div class="banner-shape-3">
            <img src="../../assets/images/shape/shape-3.png" alt="Image">
        </div>
    </div>
</template>

<script>

export default {
    name: 'Banner'
}
</script>
