<template>
    <div class="latest-news-area pb-75">
        <div class="container">
            <div class="section-title">
                <h2>Latest News From Blog</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit adipiscing eleifend dictum potenti mattis viverra eget quam lacus enim porttitor bibendum elit dui nisl.</p>
            </div>
        </div>
        
        <div class="container-fluid">
            <div class="latest-news-slides">
                <carousel 
                    :settings="settings"
                    :breakpoints="breakpoints"
                >
                    <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div class="latest-news-card white-color-content">
                            <div class="news-image">
                                <router-link to="/blog-details">
                                    <img :src="slide.image" alt="image">
                                </router-link>
                            </div>
                            <div class="news-content">
                                <h3>
                                    <router-link to="/blog-details">
                                        {{slide.title}}
                                    </router-link>
                                </h3>
                                <p>{{slide.description}}</p>
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <Navigation />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Blog',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/more-home/latest-news/news-1.jpg'),
                title: 'Being The Best-selling Smartphone In This Year',
                description: 'Lorem ipsum dolor sit amet delectir consecte adipiscing elit.',
            },
            {
                id: 2,
                image: require('../../assets/images/more-home/latest-news/news-2.jpg'),
                title: 'Security In A Fragment World Of Workload',
                description: 'Lorem ipsum dolor sit amet delectir consecte adipiscing elit.',
            },
            {
                id: 3,
                image: require('../../assets/images/more-home/latest-news/news-3.jpg'),
                title: 'Dhs Issues Emergency Directive To Prevent Hacking Attack',
                description: 'Lorem ipsum dolor sit amet delectir consecte adipiscing elit.',
            },
            {
                id: 4,
                image: require('../../assets/images/more-home/latest-news/news-4.jpg'),
                title: 'Drughydrus  Add Google Drive To Roughrobin Torjan',
                description: 'Lorem ipsum dolor sit amet delectir consecte adipiscing elit.',
            },
            {
                id: 5,
                image: require('../../assets/images/more-home/latest-news/news-1.jpg'),
                title: 'Being The Best-selling Smartphone In This Year',
                description: 'Lorem ipsum dolor sit amet delectir consecte adipiscing elit.',
            },
            {
                id: 6,
                image: require('../../assets/images/more-home/latest-news/news-2.jpg'),
                title: 'Security In A Fragment World Of Workload',
                description: 'Lorem ipsum dolor sit amet delectir consecte adipiscing elit.',
            },
            {
                id: 7,
                image: require('../../assets/images/more-home/latest-news/news-3.jpg'),
                title: 'Dhs Issues Emergency Directive To Prevent Hacking Attack',
                description: 'Lorem ipsum dolor sit amet delectir consecte adipiscing elit.',
            },
            {
                id: 8,
                image: require('../../assets/images/more-home/latest-news/news-4.jpg'),
                title: 'Drughydrus  Add Google Drive To Roughrobin Torjan',
                description: 'Lorem ipsum dolor sit amet delectir consecte adipiscing elit.',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
        },
    }),
})
</script>