<template>
    <div class="project-area pb-100">
        <div class="container-fluid">
            <div class="section-title">
                <h2>Quvia Recent Project Case</h2>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloribus quam neque quibusdam corrupti aspernatur corporis alias nisi dolorum expedita veritatis voluptates minima.</p>
            </div>

            <div class="solutions-wrap">
                <carousel 
                    :autoplay="5000"
                    :settings="settings"
                    :wrap-around="true"
                    :breakpoints="breakpoints"
                >
                    <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div :class="slide.class">
                            <div class="solutions-content">
                                <h3>{{slide.title}}</h3>
                                <p>{{slide.description}}</p>
                                <router-link :to="slide.btnLink" class="read-more">
                                    {{slide.btnText}}
                                </router-link>
                            </div>
                        </div>
                    </slide>
                    
                    <template #addons>
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Solutions',
    components: {
        Carousel,
        Slide,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                class: 'single-solutions solutions-time-bg-7',
                title: 'Hacking Parotection Software',
                description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Officia, obcaecati praesentium. Labore sint recusandae perspiciatis laudantium, deleniti non',
                btnText: 'Read More',
                btnLink: '/contact',
            },
            {
                id: 2,
                class: 'single-solutions solutions-time-bg-8',
                title: 'Security Awarness Training',
                description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Officia, obcaecati praesentium. Labore sint recusandae perspiciatis laudantium, deleniti non',
                btnText: 'Read More',
                btnLink: '/contact',
            },
            {
                id: 3,
                class: 'single-solutions solutions-time-bg-9',
                title: 'Cyber Security Manged IT',
                description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Officia, obcaecati praesentium. Labore sint recusandae perspiciatis laudantium, deleniti non',
                btnText: 'Read More',
                btnLink: '/contact',
            },
            {
                id: 4,
                class: 'single-solutions solutions-time-bg-8',
                title: 'Hacking Parotection Software',
                description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Officia, obcaecati praesentium. Labore sint recusandae perspiciatis laudantium, deleniti non',
                btnText: 'Read More',
                btnLink: '/contact',
            },
            {
                id: 5,
                class: 'single-solutions solutions-time-bg-9',
                title: 'Security Awarness Training',
                description: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Officia, obcaecati praesentium. Labore sint recusandae perspiciatis laudantium, deleniti non',
                btnText: 'Read More',
                btnLink: '/contact',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
        },
    }),
})
</script>