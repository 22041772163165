<template>
    <div>
        <NavbarStyleFour />
        <Banner />
        <Features />
        <SaferWorld />
        <CompleteWebsite />
        <Funfacts />
        <SuccessProjects />
        <Testimonials />
        <ElectronicProtection />
        <Plans />
        <CybersecurityOperation />
        <TeamStyleOne />
        <BlogStyleOne />
        <FooterStyleThree />
    </div>
</template>

<script>
import NavbarStyleFour from '../Layout/NavbarStyleFour'
import Banner from '../HomeSeven/Banner'
import Features from '../Common/Features'
import SaferWorld from '../Common/SaferWorld'
import CompleteWebsite from '../HomeSeven/CompleteWebsite'
import Funfacts from '../Common/Funfacts'
import SuccessProjects from '../Common/SuccessProjects'
import Testimonials from '../Common/Testimonials'
import ElectronicProtection from '../Common/ElectronicProtection'
import Plans from '../Common/Plans'
import CybersecurityOperation from '../HomeSeven/CybersecurityOperation'
import TeamStyleOne from '../Common/TeamStyleOne'
import BlogStyleOne from '../Common/BlogStyleOne'
import FooterStyleThree from '../Layout/FooterStyleThree'

export default {
    name: 'HomePageSeven',
    components: {
        NavbarStyleFour,
        Banner,
        Features,
        SaferWorld,
        CompleteWebsite,
        Funfacts,
        SuccessProjects,
        Testimonials,
        ElectronicProtection,
        Plans,
        CybersecurityOperation,
        TeamStyleOne,
        BlogStyleOne,
        FooterStyleThree,
    }
}
</script>
