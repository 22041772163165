<template>
    <div>
        <Navbar />
        <PageTitle />
        <Team />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Team/PageTitle'
import Team from '../Team/Team'
import FooterTop from '../Layout/FooterTop'
import FooterBottom from '../Layout/FooterBottom'

export default {
    name: 'TeamPage',
    components: {
        Navbar,
        PageTitle,
        Team,
        FooterTop,
        FooterBottom,
    }
}
</script>
