<template>
    <div class="overview-area">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="overview-content-box">
                        <img src="../../assets/images/more-home/overview/overview-1.jpg" alt="image">

                        <div class="content">
                            <h3>Provide The Best Solution By Our Expert</h3>
                            <router-link to="/contact" class="default-btn">Contact Us</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="overview-content-box">
                        <img src="../../assets/images/more-home/overview/overview-2.jpg" alt="image">

                        <div class="content">
                            <h3>Our Recent Digital & Strategy Services</h3>
                            <router-link to="/services-one" class="default-btn">View All Services</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Overview'
}
</script>