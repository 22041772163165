<template>
    <div class="pricing-area">
        <div class="container">
            <div class="section-title">
                <h2>Buy Our Plans & Packages</h2>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloribus quam neque quibusdam corrupti aspernatur corporis alias nisi dolorum expedita veritatis voluptates minima.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-pricing">
                        <div class="pricing-content">
                            <h3>One Time</h3>
                            <h1>Free</h1>
                        </div>

                        <ul>
                            <li>
                                <i class="bx bx-check"></i>
                                The Departure Of The Expect
                            </li>
                            <li>
                                <i class="bx bx-check"></i>
                                Remote Administrator
                            </li>
                            <li>
                                <i class="bx bx-check"></i>
                                Configure Software
                            </li>
                            <li>
                                <span>
                                    <i class="bx bx-x"></i>
                                    Special Application
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class="bx bx-x"></i>
                                    24/7 Support
                                </span>
                            </li>
                        </ul>

                        <router-link to="/contact" class="default-btn">
                            Order Now
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-pricing active">
                        <div class="pricing-content">
                            <h3>Business</h3>
                            <h1>$70 <sub>/ per month</sub></h1>
                        </div>

                        <ul>
                            <li>
                                <i class="bx bx-check"></i>
                                The Departure Of The Expect
                            </li>
                            <li>
                                <i class="bx bx-check"></i>
                                Remote Administrator
                            </li>
                            <li>
                                <i class="bx bx-check"></i>
                                Configure Software
                            </li>
                            <li>
                                <i class="bx bx-check"></i>
                                Special Application
                            </li>
                            <li>
                                <span>
                                    <i class="bx bx-x"></i>
                                    24/7 Support
                                </span>
                            </li>
                        </ul>

                        <router-link to="/contact" class="default-btn">
                            Order Now
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                    <div class="single-pricing">
                        <div class="pricing-content">
                            <h3>Exclusive</h3>
                            <h1>$120 <sub>/ per month</sub></h1>
                        </div>

                        <ul>
                            <li>
                                <i class="bx bx-check"></i>
                                The Departure Of The Expect
                            </li>
                            <li>
                                <i class="bx bx-check"></i>
                                Remote Administrator
                            </li>
                            <li>
                                <i class="bx bx-check"></i>
                                Configure Software
                            </li>
                            <li>
                                <i class="bx bx-check"></i>
                                Special Application
                            </li>
                            <li>
                                <i class="bx bx-check"></i>
                                24/7 Support
                            </li>
                        </ul>

                        <router-link to="/contact" class="default-btn">
                            Order Now
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pricing'
}
</script>