<template>
    <div>
        <Navbar />
        <Banner />
        <Partner />
        <Cybersecurity />
        <Security />
        <Solutions />
        <Complete />
        <Manual />
        <Counter />
        <Pricing />
        <Client />
        <Blog />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import Banner from '../HomeTwo/Banner'
import Partner from '../Common/Partner'
import Cybersecurity from '../HomeTwo/Cybersecurity'
import Security from '../HomeTwo/Security'
import Solutions from '../HomeTwo/Solutions'
import Complete from '../HomeTwo/Complete'
import Manual from '../HomeTwo/Manual'
import Counter from '../HomeTwo/Counter'
import Pricing from '../Common/Pricing'
import Client from '../Common/Client'
import Blog from '../Common/Blog'
import FooterTop from '../Layout/FooterTop'
import FooterBottom from '../Layout/FooterBottom'

export default {
    name: 'HomePageTwo',
    components: {
        Navbar,
        Banner,
        Partner,
        Cybersecurity,
        Security,
        Solutions,
        Complete,
        Manual,
        Counter,
        Pricing,
        Client,
        Blog,
        FooterTop,
        FooterBottom,
    }
}
</script>
