<template>
    <div class="performance-area bg-none pb-70">
        <div class="container">
            <div class="section-title">
                <h2>High-Performance Solutions</h2>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloribus quam neque quibusdam corrupti aspernatur corporis alias nisi dolorum expedita veritatis voluptates minima.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="single-security">
                        <i class="flaticon-website"></i>
                        <h3>Confidential Information</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor sit amet, dolor consectetur</p>

                        <router-link to="/contact" class="read-more">
                            Read More
                        </router-link>
                        <img src="../../assets/images/shape/shape-8.png" alt="Image">
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-security">
                        <i class="flaticon-profile"></i>
                        <h3>Mobile Security</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor sit amet, dolor consectetur</p>

                        <router-link to="/contact" class="read-more">
                            Read More
                        </router-link>
                        <img src="../../assets/images/shape/shape-8.png" alt="Image">
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-security">
                        <i class="flaticon-content"></i>
                        <h3>Website Protection</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor sit amet, dolor consectetur</p>

                        <router-link to="/contact" class="read-more">
                            Read More
                        </router-link>
                        <img src="../../assets/images/shape/shape-8.png" alt="Image">
                    </div>
                </div>
                
                <div class="col-lg-4 col-sm-6">
                    <div class="single-security">
                        <i class="flaticon-cyber"></i>
                        <h3>Hacking Protection</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor sit amet, dolor consectetur</p>

                        <router-link to="/contact" class="read-more">
                            Read More
                        </router-link>
                        <img src="../../assets/images/shape/shape-8.png" alt="Image">
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-security">
                        <i class="flaticon-profile"></i>
                        <h3>Mobile Security</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor sit amet, dolor consectetur</p>

                        <router-link to="/contact" class="read-more">
                            Read More
                        </router-link>
                        <img src="../../assets/images/shape/shape-8.png" alt="Image">
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-security">
                        <i class="flaticon-profile"></i>
                        <h3>Mobile Security</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor sit amet, dolor consectetur</p>

                        <router-link to="/contact" class="read-more">
                            Read More
                        </router-link>
                        <img src="../../assets/images/shape/shape-8.png" alt="Image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Performance'
}
</script>