<template>
    <div class="widget-area" id="secondary">
        <!-- <div class="widget widget_search">
            <h3 class="widget-title">Search Now</h3>
            <div class="post-wrap">
                <form class="search-form">
                    <label>
                        <span class="screen-reader-text">Search for:</span>
                        <input type="search" class="search-field" placeholder="Search...">
                    </label>
                    <button type="submit"><i class='bx bx-search'></i></button>
                </form>
            </div>
        </div> -->
        
        <div class="widget widget-peru-posts-thumb">
            <h3 class="widget-title">Popular Posts</h3>
            <div class="post-wrap">
                <div class="item">
                    <router-link to="/blog-details" class="thumb">
                        <span class="fullimage cover bg1" role="img"></span>
                    </router-link>
                    <div class="info">
                        <time datetime="2022-06-30">April 20, 2022</time>
                        <h4 class="title usmall">
                            <router-link to="/blog-details">
                                Drughydrus Add Google Drive To Roughrobin Torjan
                            </router-link>
                        </h4>
                    </div>

                    <div class="clear"></div>
                </div>

                <div class="item">
                    <router-link to="/blog-details" class="thumb">
                        <span class="fullimage cover bg2" role="img"></span>
                    </router-link>
                    <div class="info">
                        <time datetime="2022-06-30">Jun 21, 2022</time>
                        <h4 class="title usmall">
                            <router-link to="/blog-details">
                                DHS Issues Emergency Directive To Prevent Hacking Attack
                            </router-link>
                        </h4>
                    </div>

                    <div class="clear"></div>
                </div>

                <div class="item">
                    <router-link to="/blog-details" class="thumb">
                        <span class="fullimage cover bg3" role="img"></span>
                    </router-link>
                    <div class="info">
                        <time datetime="2022-06-30">Jun  22, 2022</time>
                        <h4 class="title usmall">
                            <router-link to="/blog-details">
                                Security In A Fragment World Of Workload
                            </router-link> 
                        </h4>
                    </div>

                    <div class="clear"></div>
                </div>
            </div>
        </div>
 
        <!-- <div class="widget widget_categories">
            <h3 class="widget-title">Categories</h3>
            <div class="post-wrap">
                <ul>
                    <li>
                        <router-link to="/blog-grid">Blockchain <span>(10)</span></router-link>
                    </li>
                    <li>
                        <router-link to="/blog-grid">Cyber security <span>(20)</span></router-link>
                    </li>
                    <li>
                        <router-link to="/blog-grid">Cybercrime <span>(10)</span></router-link>
                    </li>
                    <li>
                        <router-link to="/blog-grid">Global news <span>(12)</span></router-link>
                    </li>
                    <li>
                        <router-link to="/blog-grid">Ransomware <span>(16)</span></router-link>
                    </li>
                </ul>
            </div>
        </div> -->
 
        <!-- <div class="widget widget_tag_cloud">
            <h3 class="widget-title">Tags</h3>
            <div class="post-wrap">
                <div class="tagcloud">
                    <router-link to="/blog-grid">Blockchain (3)</router-link>
                    <router-link to="/blog-grid">Cyber security (3)</router-link>
                    <router-link to="/blog-grid">Cybercrime (2)</router-link>
                    <router-link to="/blog-grid">Global news (2)</router-link>
                    <router-link to="/blog-grid">Ransomware (1)</router-link>
                    <router-link to="/blog-grid">Whitepapers (2) </router-link>
                </div>
            </div>
        </div>        -->
    </div>
</template>

<script>


export default {
    name: 'BlogSidebar',
    props:["PostsSide"],
    
}
</script>