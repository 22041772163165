<template>
    <div class="quvia-features-area pb-75">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-6">
                    <div class="single-quvia-features-card bg-382549">
                        <h3><img src="../../assets/images/more-home/features/features-1.png" alt="image"> Security</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-quvia-features-card bg-382549">
                        <h3><img src="../../assets/images/more-home/features/features-2.png" alt="image"> Privacy</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="single-quvia-features-card bg-382549">
                        <h3><img src="../../assets/images/more-home/features/features-3.png" alt="image"> Industry Certified</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>