<template>
    <section class="grph-area bg-color ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="grph-img">
                        <img src="../../assets/images/graph-img.png" alt="Image">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="graph-content">
                        <h2>Cyber security Satistics Graph</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsumv</p>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel. </p>

                        <h3>15 Ways To protect Your Bussiness From A Cyber Attack</h3>
                        <a href="#" class="default-btn">
                            Download
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    name: 'Graph'
}
</script>