<template>
    <div class="cyber-defenses-area pb-100">
        <div class="container">
            <div class="section-title">
                <h2>Expert Support To Enhance Your Cyber Defenses</h2>
            </div>

            <div class="cyber-defenses-list-tabs">
                <div class="tabs">
                    <ul class="tabs__header">
                        <li 
                            class="tabs__header-item" 
                            v-for="tab in tabs" 
                            v-on:click="selectTab (tab.id)" 
                            v-bind:class="{ 'active': activeTab == tab.id }"
                            :key="tab.id"
                        >
                            {{tab.title}}
                        </li>
                    </ul>
                </div>
                <div class="tabs__container">
                    <div 
                        class="tabs__list"
                        ref='tabsList'
                    >
                        <div 
                            class="tabs__list-tab"
                            v-for="tab in tabs"
                            v-bind:class="{ 'active': activeTab == tab.id }"
                            :key="tab.id"
                        >
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-12">
                                    <div class="cyber-defenses-content">
                                        <h3>{{tab.title}}</h3>
                                        <p>{{tab.descOne}}</p>
                                        <div class="row justify-content-center">
                                            <div 
                                                class="col-lg-6 col-md-6"
                                                v-for="item in tab.listsItems"
                                                :key="item.id"
                                            >
                                                <ul class="cyber-list">
                                                    <li
                                                        v-for="list in item.lists"
                                                        :key="list.id"
                                                    >
                                                        <i :class="list.icon"></i> 
                                                        {{list.text}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <p>{{tab.descTwo}}</p>
                                        <div class="cyber-btn">
                                            <router-link :to="tab.btnLink" class="default-btn">{{tab.btnText}}</router-link>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-12">
                                    <div class="cyber-defenses-image">
                                        <img class="rounded-circle" :src="tab.image" alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="cyber-defenses-shape">
            <img src="../../assets/images/more-home/cyber-defenses/cyber-defenses-shape.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'CyberDefenses',
    data () {
        return {
            activeTab: 1,
            offsetRight: 0,
            tabs: [
                {
                    id: 1,
                    title: 'Banking',
                    descOne: 'Lorem ipsum dolor sit amet consecte adipi scing elit adipiscing eleifend dictum poteder mattis viverra lorem ipsum dolor sit amet consecte adipisci ng elit adipiscingdiper eleifend dictum potenti mattis viverra.',
                    listsItems: [
                        {
                            id: 1,
                            lists: [
                                {
                                    id: 1,
                                    icon: 'bx bx-check',
                                    text: 'Focus on The Basics',
                                },
                                {
                                    id: 23,
                                    icon: 'bx bx-check',
                                    text: 'Educate Customers',
                                },
                            ]
                        },
                        {
                            id: 2,
                            lists: [
                                {
                                    id: 1,
                                    icon: 'bx bx-check',
                                    text: 'Be Proactive',
                                },
                                {
                                    id: 23,
                                    icon: 'bx bx-check',
                                    text: 'Tighten Internal Controls',
                                },
                            ]
                        }
                    ],
                    descTwo: 'Lorem ipsum dolor sit amet consecte adipi scing elit adipiscing eleifend dictum poteder mattis viverra lorem ipsum dolor sit amet consecte adipisci ng elit adipiscingdiper eleifend dictum potenti mattis viverra.',
                    btnText: 'Get Started Now',
                    btnLink: '/contact',
                    image: require('../../assets/images/more-home/cyber-defenses/cyber-defenses-3.jpg')
                },
                {
                    id: 2,
                    title: 'Manufacturing',
                    descOne: 'Lorem ipsum dolor sit amet consecte adipi scing elit adipiscing eleifend dictum poteder mattis viverra lorem ipsum dolor sit amet consecte adipisci ng elit adipiscingdiper eleifend dictum potenti mattis viverra.',
                    listsItems: [
                        {
                            id: 1,
                            lists: [
                                {
                                    id: 1,
                                    icon: 'bx bx-check',
                                    text: 'Focus on The Basics',
                                },
                                {
                                    id: 23,
                                    icon: 'bx bx-check',
                                    text: 'Educate Customers',
                                },
                            ]
                        },
                        {
                            id: 2,
                            lists: [
                                {
                                    id: 1,
                                    icon: 'bx bx-check',
                                    text: 'Be Proactive',
                                },
                                {
                                    id: 23,
                                    icon: 'bx bx-check',
                                    text: 'Tighten Internal Controls',
                                },
                            ]
                        }
                    ],
                    descTwo: 'Lorem ipsum dolor sit amet consecte adipi scing elit adipiscing eleifend dictum poteder mattis viverra lorem ipsum dolor sit amet consecte adipisci ng elit adipiscingdiper eleifend dictum potenti mattis viverra.',
                    btnText: 'Get Started Now',
                    btnLink: '/contact',
                    image: require('../../assets/images/more-home/cyber-defenses/cyber-defenses-2.jpg')
                },
                {
                    id: 3,
                    title: 'Oil & Gas',
                    descOne: 'Lorem ipsum dolor sit amet consecte adipi scing elit adipiscing eleifend dictum poteder mattis viverra lorem ipsum dolor sit amet consecte adipisci ng elit adipiscingdiper eleifend dictum potenti mattis viverra.',
                    listsItems: [
                        {
                            id: 1,
                            lists: [
                                {
                                    id: 1,
                                    icon: 'bx bx-check',
                                    text: 'Focus on The Basics',
                                },
                                {
                                    id: 23,
                                    icon: 'bx bx-check',
                                    text: 'Educate Customers',
                                },
                            ]
                        },
                        {
                            id: 2,
                            lists: [
                                {
                                    id: 1,
                                    icon: 'bx bx-check',
                                    text: 'Be Proactive',
                                },
                                {
                                    id: 23,
                                    icon: 'bx bx-check',
                                    text: 'Tighten Internal Controls',
                                },
                            ]
                        }
                    ],
                    descTwo: 'Lorem ipsum dolor sit amet consecte adipi scing elit adipiscing eleifend dictum poteder mattis viverra lorem ipsum dolor sit amet consecte adipisci ng elit adipiscingdiper eleifend dictum potenti mattis viverra.',
                    btnText: 'Get Started Now',
                    btnLink: '/contact',
                    image: require('../../assets/images/more-home/cyber-defenses/cyber-defenses-3.jpg')
                },
                {
                    id: 4,
                    title: 'Insurance',
                    descOne: 'Lorem ipsum dolor sit amet consecte adipi scing elit adipiscing eleifend dictum poteder mattis viverra lorem ipsum dolor sit amet consecte adipisci ng elit adipiscingdiper eleifend dictum potenti mattis viverra.',
                    listsItems: [
                        {
                            id: 1,
                            lists: [
                                {
                                    id: 1,
                                    icon: 'bx bx-check',
                                    text: 'Focus on The Basics',
                                },
                                {
                                    id: 23,
                                    icon: 'bx bx-check',
                                    text: 'Educate Customers',
                                },
                            ]
                        },
                        {
                            id: 2,
                            lists: [
                                {
                                    id: 1,
                                    icon: 'bx bx-check',
                                    text: 'Be Proactive',
                                },
                                {
                                    id: 23,
                                    icon: 'bx bx-check',
                                    text: 'Tighten Internal Controls',
                                },
                            ]
                        }
                    ],
                    descTwo: 'Lorem ipsum dolor sit amet consecte adipi scing elit adipiscing eleifend dictum poteder mattis viverra lorem ipsum dolor sit amet consecte adipisci ng elit adipiscingdiper eleifend dictum potenti mattis viverra.',
                    btnText: 'Get Started Now',
                    btnLink: '/contact',
                    image: require('../../assets/images/more-home/cyber-defenses/cyber-defenses-4.jpg')
                },
                {
                    id: 5,
                    title: 'Health Care',
                    descOne: 'Lorem ipsum dolor sit amet consecte adipi scing elit adipiscing eleifend dictum poteder mattis viverra lorem ipsum dolor sit amet consecte adipisci ng elit adipiscingdiper eleifend dictum potenti mattis viverra.',
                    listsItems: [
                        {
                            id: 1,
                            lists: [
                                {
                                    id: 1,
                                    icon: 'bx bx-check',
                                    text: 'Focus on The Basics',
                                },
                                {
                                    id: 23,
                                    icon: 'bx bx-check',
                                    text: 'Educate Customers',
                                },
                            ]
                        },
                        {
                            id: 2,
                            lists: [
                                {
                                    id: 1,
                                    icon: 'bx bx-check',
                                    text: 'Be Proactive',
                                },
                                {
                                    id: 23,
                                    icon: 'bx bx-check',
                                    text: 'Tighten Internal Controls',
                                },
                            ]
                        }
                    ],
                    descTwo: 'Lorem ipsum dolor sit amet consecte adipi scing elit adipiscing eleifend dictum poteder mattis viverra lorem ipsum dolor sit amet consecte adipisci ng elit adipiscingdiper eleifend dictum potenti mattis viverra.',
                    btnText: 'Get Started Now',
                    btnLink: '/contact',
                    image: require('../../assets/images/more-home/cyber-defenses/cyber-defenses-5.jpg')
                },
            ]
        }
    },
    methods: {
        selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }
    },
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    },
}
</script>