<template>
    <div class="counter-area-six bg-color pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="single-counters">
                        <i class="flaticon-anti-virus-software"></i>
                        <h2>3500+</h2>
                        <p>Customers Served Globally</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-counters">
                        <i class="flaticon-cyber"></i>
                        <h2>2500+</h2>
                        <p>Cybersecurity Projects</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-counters">
                        <i class="flaticon-content"></i>
                        <h2>85%</h2>
                        <p>Customer Retention Rate</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="single-counters">
                        <i class="flaticon-medal"></i>
                        <h2>350+</h2>
                        <p>Cybersecurity Experts</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Counter'
}
</script>