<template>
    <div class="lats-talk-area ptb-100">
        <div class="container">
            <div class="lats-talk-content">
                <h2>Ready to get started? we're here to help</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                <router-link class="default-btn six" to="/contact">
                    <i class="bx bx-file"></i>
                    Let’s Talk
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'LetsTalk'
}
</script>