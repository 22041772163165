<template>
    <div class="cybersecurity-area pb-100">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6">
                    <div class="cybersecurity-content">
                        <h2>24/7 Cybersecurity Operation Center</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>

                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <ul class="cybersecurity-item">
                                    <li>
                                        <i class="bx bx-check"></i>
                                        Managed Web Application
                                    </li>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        SIEM Threat Detection
                                    </li>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        Content Delivery Network
                                    </li>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        Website Hack Repair
                                    </li>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        Instant Malware Removal
                                    </li>
                                </ul>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <ul>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        Instant Malware Removal
                                    </li>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        Instant Malware Removal
                                    </li>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        Instant Malware Removal
                                    </li>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        Instant Malware Removal
                                    </li>
                                    <li>
                                        <i class="bx bx-check"></i>
                                        Instant Malware Removal
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 pr-0">
                    <div class="cybersecurity-img"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Cybersecurity'
}
</script>