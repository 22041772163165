<template>
    <section class="plans-area pt-100 pb-75">
        <div class="container">
            <div class="section-title">
                <h2>Buy Our Plans & Packages Monthly</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit adipiscing eleifend dictum potenti mattis viverra eget quam lacus enim porttitor bibendum elit dui nisl.</p>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-6">
                    <div class="single-plans-card">
                        <div class="pricing-header">
                            <h3>Free</h3>
                            <p>Best for individual</p>
                        </div>
                        <div class="price">
                            <h4>Free<span>/month</span></h4>
                        </div>
                        <div class="features-list">
                            <h5>ALL FEATURES:</h5>
                            <ul>
                                <li><i class='bx bx-check-circle'></i> The Departure Of The Expect</li>
                                <li><i class='bx bx-check-circle'></i> Remote Administrator</li>
                                <li><i class='bx bx-check-circle'></i> Configure Software</li>
                                <li><i class='bx bx-check-circle'></i> Special Application</li>
                                <li class="bg-C4C4C4"><i class='bx bx-check-circle'></i> 24/7 Support </li>
                                <li class="bg-C4C4C4"><i class='bx bx-check-circle'></i> Password Protection</li>
                            </ul>
                        </div>
                        <router-link to="/contact" class="link-btn">Get Started</router-link>

                        <div class="plans-shape">
                            <img src="../../assets/images/more-home/plans/shape.png" alt="image">
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="single-plans-card">
                        <div class="pricing-header">
                            <h3>Starter</h3>
                            <p>Best for Team</p>
                        </div>
                        <div class="price">
                            <h4>$49<span>/month</span></h4>
                        </div>
                        <div class="features-list">
                            <h5>ALL FEATURES:</h5>
                            <ul>
                                <li><i class='bx bx-check-circle'></i> The Departure Of The Expect</li>
                                <li><i class='bx bx-check-circle'></i> Remote Administrator</li>
                                <li><i class='bx bx-check-circle'></i> Configure Software</li>
                                <li><i class='bx bx-check-circle'></i> Special Application</li>
                                <li><i class='bx bx-check-circle'></i> 24/7 Support </li>
                                <li class="bg-C4C4C4"><i class='bx bx-check-circle'></i> Password Protection</li>
                            </ul>
                        </div>
                        <router-link to="/contact" class="link-btn">Get Started</router-link>

                        <div class="plans-shape">
                            <img src="../../assets/images/more-home/plans/shape.png" alt="image">
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="single-plans-card">
                        <div class="pricing-header">
                            <h3>Exclusive</h3>
                            <p>Best for Company</p>
                        </div>
                        <div class="price">
                            <h4>$100<span>/month</span></h4>
                        </div>
                        <div class="features-list">
                            <h5>ALL FEATURES:</h5>
                            <ul>
                                <li><i class='bx bx-check-circle'></i> The Departure Of The Expect</li>
                                <li><i class='bx bx-check-circle'></i> Remote Administrator</li>
                                <li><i class='bx bx-check-circle'></i> Configure Software</li>
                                <li><i class='bx bx-check-circle'></i> Special Application</li>
                                <li><i class='bx bx-check-circle'></i> 24/7 Support </li>
                                <li><i class='bx bx-check-circle'></i> Password Protection</li>
                            </ul>
                        </div>
                        <router-link to="/contact" class="link-btn">Get Started</router-link>

                        <div class="plans-shape">
                            <img src="../../assets/images/more-home/plans/shape.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Plans'
}
</script>