<template>
    <div>
        <Navbar />
        <PageTitle />
        <Security />
        <Solutions />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../ServicesStyleOne/PageTitle'
import Security from '../ServicesStyleOne/Security'
import Solutions from '../ServicesStyleOne/Solutions'
import FooterTop from '../Layout/FooterTop'
import FooterBottom from '../Layout/FooterBottom'

export default {
    name: 'ServicesStyleOnePage',
    components: {
        Navbar,
        PageTitle,
        Security,
        Solutions,
        FooterTop,
        FooterBottom,
    }
}
</script>
