<template>
    <div>
        <Navbar />
        <PageTitle />
        <PricingTwo />
        <Pricing />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Pricing/PageTitle'
import PricingTwo from '../Pricing/PricingTwo'
import Pricing from '../Common/Pricing'
import FooterTop from '../Layout/FooterTop'
import FooterBottom from '../Layout/FooterBottom'

export default {
    name: 'PricingPage',
    components: {
        Navbar,
        PageTitle,
        PricingTwo,
        Pricing,
        FooterTop,
        FooterBottom,
    }
}
</script>
