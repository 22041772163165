<template>
    <div class="header-area fixed-top">
        <div class="nav-area nav-area-three">
            <div :class="['navbar-area', {'is-sticky': isSticky}]">
                <div class="main-nav">
                    <nav class="navbar navbar-expand-lg">
                        <div class="container-fluid">
                            <router-link class="navbar-brand" to="/">
                                <img src="../../assets/images/logo.png" alt="image">
                            </router-link>

                            <div 
                                class="navbar-toggler"
                                @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                                v-bind:class="{ 'active': button_active_state }"
                                v-on:click="button_active_state = !button_active_state"
                            >
                                <span class="icon-bar top-bar"></span>
                                <span class="icon-bar middle-bar"></span>
                                <span class="icon-bar bottom-bar"></span>
                            </div>
                            
                            <div class="collapse navbar-collapse" :class="{ show: active }">
                                <ul class="navbar-nav m-auto">
                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            Home
                                            <i class='bx bx-chevron-right'></i>
                                        </a>

                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <router-link to="/" class="nav-link" exact>
                                                    Home One
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/home-two" class="nav-link">
                                                    Home Two
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/home-three" class="nav-link">
                                                    Home Three
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/home-four" class="nav-link">
                                                    Home Four
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/home-five" class="nav-link">
                                                    Home Five
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/home-six" class="nav-link">
                                                    Home Six
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/home-seven" class="nav-link">
                                                    Home Seven
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/home-eight" class="nav-link">
                                                    Home Eight
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/home-nine" class="nav-link">
                                                    Home Nine
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/about-us" class="nav-link">
                                            About Us
                                        </router-link>
                                    </li>

                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            Pages 
                                            <i class='bx bx-chevron-right'></i>
                                        </a>

                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <router-link to="/pricing" class="nav-link">
                                                    Pricing
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/testimonials" class="nav-link">
                                                    Testimonials
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/team" class="nav-link">
                                                    Team
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/faq" class="nav-link">
                                                    FAQ
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <a href="#" class="nav-link">
                                                    User 
                                                    <i class='bx bx-chevron-right'></i>
                                                </a>

                                                <ul class="dropdown-menu">
                                                    <li class="nav-item">
                                                        <router-link to="/log-in" class="nav-link">
                                                            Log In
                                                        </router-link>
                                                    </li>

                                                    <li class="nav-item">
                                                        <router-link to="/sign-in" class="nav-link">
                                                            Sign In
                                                        </router-link>
                                                    </li>

                                                    <li class="nav-item">
                                                        <router-link to="/recover-password" class="nav-link">
                                                            Recover Password
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/terms-conditions" class="nav-link">
                                                    Terms & Conditions
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/privacy-policy" class="nav-link">
                                                    Privacy Policy
                                                </router-link>
                                            </li>
                                        
                                            <li class="nav-item">
                                                <router-link to="/error" class="nav-link">
                                                    Error Page
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            Services 
                                            <i class='bx bx-chevron-right'></i>
                                        </a>

                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                            <router-link to="/services-one" class="nav-link">
                                                Services One
                                            </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/services-two" class="nav-link">
                                                    Services Two
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/services-three" class="nav-link">
                                                    Services Three
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/services-details" class="nav-link">
                                                    Services Details
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            Blog 
                                            <i class='bx bx-chevron-right'></i>
                                        </a>

                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <router-link to="/blog-grid" class="nav-link">
                                                    Blog Grid
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/blog-left-sidebar" class="nav-link">
                                                    Blog Left Sidebar
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/blog-right-sidebar" class="nav-link">
                                                    Blog Right Sidebar
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/blog-details" class="nav-link">
                                                    Blog Details
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/contact" class="nav-link">
                                            Contact
                                        </router-link>
                                    </li>
                                </ul>
                            </div>

                            <div class="others-option">
                                <div class="call-us">
                                    <a href="tel:+892-569-756">
                                        <i class="bx bxs-phone-call"></i>
                                        +892-569-756
                                    </a>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavbarStyleTwo',
    data(){
        return {
            isSticky: false,
            active: false,
            button_active_state: false
        }
    },

    mounted(){
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 100){
                that.isSticky = true
            } else {
                that.isSticky = false
            }
        })
    }
}
</script>