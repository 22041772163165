<template>
    <div class="our-serve-area bg-color-f9f9f9 pt-100 pb-70">
        <div class="container">
            <div class="section-title-six">
                <span>Our Serve area</span>
                <h2>Industry Expertise</h2>
                <p>Lorem ipsum dolor sit amet, conseactetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-serve">
                        <i class="bx bxs-bank"></i>
                        <h3>Banking</h3>
                        <p>Lorem ipsum dolor sit amet,  adipiscing elit, sed do eiusmod tempor incididunt ut labore. Quis ipsum suspendisse</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-serve">
                        <i class="bx bx-cog"></i>
                        <h3>Manufacturing</h3>
                        <p>Lorem ipsum dolor sit amet,  adipiscing elit, sed do eiusmod tempor incididunt ut labore. Quis ipsum suspendisse</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-serve">
                        <i class="bx bxs-vial"></i>
                        <h3>Oil and Gas</h3>
                        <p>Lorem ipsum dolor sit amet,  adipiscing elit, sed do eiusmod tempor incididunt ut labore. Quis ipsum suspendisse</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-serve">
                        <i class="bx bx-check-shield"></i>
                        <h3>Insurance</h3>
                        <p>Lorem ipsum dolor sit amet,  adipiscing elit, sed do eiusmod tempor incididunt ut labore. Quis ipsum suspendisse</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-serve">
                        <i class="bx bx-server"></i>
                        <h3>Public Services</h3>
                        <p>Lorem ipsum dolor sit amet,  adipiscing elit, sed do eiusmod tempor incididunt ut labore. Quis ipsum suspendisse</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-serve">
                        <i class="bx bx-heart"></i>
                        <h3>Healthcare</h3>
                        <p>Lorem ipsum dolor sit amet,  adipiscing elit, sed do eiusmod tempor incididunt ut labore. Quis ipsum suspendisse</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Serve'
}
</script>