<template>
    <section class="complete-area pb-100">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 ps-0">
                    <div class="complete-img">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="complete-content">
                        <h2>The most Complete and Effective Protection for Your Home and Office</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor elit incididunt ut labore et dolore magna aliqua. Quis ipsum</p>

                        <div class="row">
                            <div class="col-lg-6 col-sm-6">
                                <div class="single-security">
                                    <i class="flaticon-order"></i>
                                    <h3>Check and Search Hazards</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="single-security">
                                    <i class="flaticon-anti-virus-software"></i>
                                    <h3>Install and Configure Software</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="single-security mb-0 mb-rs-need">
                                    <i class="flaticon-scientist"></i>
                                    <h3>Departure of the Our Experts</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-6">
                                <div class="single-security mb-0">
                                    <i class="flaticon-technical-support"></i>
                                    <h3>24/7 Support and Remote Admit</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 

        <div class="complete-shape">
            <img src="../../assets/images/complete-shape.png" alt="Image">
        </div>
    </section>
</template>

<script>

export default {
    name: 'Complete'
}
</script>