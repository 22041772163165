<template>
  <div>

    <div>{{ content }}</div>


  </div>
</template>

<script>
import UmbracoService from '../services/umbraco.service'; // Correct the path to UmbracoService

export default {
  props: {
    itemId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      content: null,
      Umb: []
    };
  },

  created() {
    UmbracoService.getContentItem(this.itemId) // this.itemId olarak düzeltildi
        .then(data => {
          this.Umb = data;
          console.log("hereeeDOTCEVDETS");



          console.log("Content fetched:", this.content);
        })
        .catch(error => {
          console.error('Hata:', error.message);
        });
  },

  methods: {}
};
</script>
