<template>
    <div class="body-with-black-color">
        <NavbarStyleFour class="nav-with-black-color" />
        <Banner />
        <Features />
        <SaferWorld />
        <CompleteWebsite />
        <CyberDefenses />
        <CybersecurityOperation />
        <Testimonials />
        <ElectronicProtection />
        <Blog />
        <FooterStyleThree />
    </div>
</template>

<script>
import NavbarStyleFour from '../Layout/NavbarStyleFour'
import Banner from '../HomeNine/Banner'
import Features from '../HomeNine/Features'
import SaferWorld from '../HomeNine/SaferWorld'
import CompleteWebsite from '../HomeNine/CompleteWebsite'
import CyberDefenses from '../HomeNine/CyberDefenses'
import CybersecurityOperation from '../HomeNine/CybersecurityOperation'
import Testimonials from '../Common/Testimonials'
import ElectronicProtection from '../HomeNine/ElectronicProtection'
import Blog from '../HomeNine/Blog'
import FooterStyleThree from '../Layout/FooterStyleThree'

export default {
    name: 'HomePageNine',
    components: {
        NavbarStyleFour,
        Banner,
        Features,
        SaferWorld,
        CompleteWebsite,
        CyberDefenses,
        CybersecurityOperation,
        Testimonials,
        ElectronicProtection,
        Blog,
        FooterStyleThree,
    }
}
</script>
