<template>
    <div>
        <Navbar />
        <PageTitle />
        <PrivacyPolicy />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../PrivacyPolicy/PageTitle'
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'
import FooterTop from '../Layout/FooterTop'
import FooterBottom from '../Layout/FooterBottom'

export default {
    name: 'PrivacyPolicyPage',
    components: {
        Navbar,
        PageTitle,
        PrivacyPolicy,
        FooterTop,
        FooterBottom,
    }
}
</script>
