<template>
  <div>
    <Navbar />
    <PageTitle :PostsData="NavbarItems" />
    <BlogArea :PostsData="Posts" />
    <FooterTop />
    <FooterBottom />
  </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../BlogGrid/PageTitle'
import BlogArea from '../BlogGrid/BlogArea'
import FooterTop from '../Layout/FooterTop'
import FooterBottom from '../Layout/FooterBottom'
import Posts from '../../assets/data/Posts.json'
import NavbarItems from '../../assets/data/NavbarItems.json'
export default {
  name: 'BlogGridPage',
  data() {
    return {
      Posts: Posts.data,
      NavbarItems: NavbarItems.data
    }
  },
  components: {
    Navbar,
    PageTitle,
    BlogArea,
    FooterTop,
    FooterBottom,
  },
  methods: {
    filterObjectsByCategoryId(arr, targetCategoryId) {
      return arr.filter(obj => obj.categoryId.includes(targetCategoryId));
    },
    filterCCategoryByCategoryId(arr, targetCategoryId) {
      return arr.filter(obj => obj.submenu && obj.submenu.filter(x => x.id === targetCategoryId));
    },
    filteredData() {
      return this.NavbarItems.filter(item => {
        if (item.submenu && item.submenu.length > 0) {
          return item.submenu.some(subitem => subitem.id === parseInt(this.$route.params.id));
        }
        return false;
      });
    }
  },
  created() {

    this.Posts = this.filterObjectsByCategoryId(this.Posts, parseInt(this.$route.params.id));
    const test = this.filteredData();
    this.NavbarItems = test

  },
  watch: {
    changeURL() {
      this.$router.push({ name: "/blog-details/", params: { id: this.Posts } })
    }
  }


}
</script>