<template>
    <div>
        <Navbar />
        <PageTitle />
        <Client />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Testimonials/PageTitle'
import Client from '../Testimonials/Client'
import FooterTop from '../Layout/FooterTop'
import FooterBottom from '../Layout/FooterBottom'

export default {
    name: 'TestimonialsPage',
    components: {
        Navbar,
        PageTitle,
        Client,
        FooterTop,
        FooterBottom,
    }
}
</script>
