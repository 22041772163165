<template>
    <div class="transform-area pb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 pr-0">
                    <div class="transform-img">
                        <img src="../../assets/images/transform-img.jpg" alt="Image">
                    </div>
                </div>
                <div class="col-lg-6 ps-0">
                    <div class="transform-content">
                        <h2>Transform Your Digital Workflow Be productive Reduce risk</h2>
                        <p>Lorem ipsum dolor sit elit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt  labore et dolore magna aliqua. Quis ipsum suspendisse Workflow Be productive consectetur adipiscing elit, sed</p>

                        <div class="progress-content">
                            <div class="progress-text">
                                <span>Development</span>
                                <p class="pera-1">60%</p>
                            </div>
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" style="width: 60%;" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>

                        <div class="progress-content">
                            <div class="progress-text">
                                <span>Branding Design</span>
                                <p class="pera-2">75%</p>
                            </div>
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" style="width: 75%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>

                        <div class="progress-content">
                            <div class="progress-text">
                                <span>SEO & Digital Marketing</span>
                                <p class="pera-3">95%</p>
                            </div>
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" style="width: 95%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape-1">
            <img src="../../assets/images/shape/shape-5.png" alt="Image">
        </div>
        <div class="shape-2">
            <img src="../../assets/images/shape/shape-6.png" alt="Image">
        </div>
    </div>
</template>

<script>

export default {
    name: 'Transform'
}
</script>