<template>
    <div>
        <NavbarStyleFour />
        <Banner />
        <Features class="with-black-color" />
        <SaferWorld class="with-black-color pt-0" />
        <IncludedService />
        <Funfacts />
        <ElectronicProtection />
        <TeamStyleOne />
        <SuccessProjects />
        <Testimonials />
        <Plans class="bg-white" />
        <Partner />
        <Overview />
        <BlogStyleOne />
        <FooterStyleThree />
    </div>
</template>

<script>
import NavbarStyleFour from '../Layout/NavbarStyleFour'
import Banner from '../HomeEight/Banner'
import Features from '../Common/Features'
import SaferWorld from '../Common/SaferWorld'
import IncludedService from '../HomeEight/IncludedService'
import Funfacts from '../Common/Funfacts'
import ElectronicProtection from '../Common/ElectronicProtection'
import TeamStyleOne from '../Common/TeamStyleOne'
import SuccessProjects from '../Common/SuccessProjects'
import Testimonials from '../Common/Testimonials'
import Plans from '../Common/Plans'
import Partner from '../HomeEight/Partner'
import Overview from '../HomeEight/Overview'
import BlogStyleOne from '../Common/BlogStyleOne'
import FooterStyleThree from '../Layout/FooterStyleThree'

export default {
    name: 'HomePageEight',
    components: {
        NavbarStyleFour,
        Banner,
        Features,
        SaferWorld,
        IncludedService,
        Funfacts,
        ElectronicProtection,
        TeamStyleOne,
        SuccessProjects,
        Testimonials,
        Plans,
        Partner,
        Overview,
        BlogStyleOne,
        FooterStyleThree,
    }
}
</script>
