import axios from 'axios';

const UmbracoService = {
    getContentItem() {
        return axios.get('https://backend.shopandmag.com.tr/umbraco/delivery/api/v1/content')
            .then(response => {
                return response.data;
            })
            .catch(error => {
                console.error('Umbraco API Error:', error);
                throw new Error('Umbraco API Error');
            });
    }, // Added comma here

    // Additional request functions can be added here...
};

export default UmbracoService;
