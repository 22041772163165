<template>
    <div class="funfacts-style-two-area pt-100 pb-75">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-sm-6 col-6 col-md-6">
                    <div class="single-funfacts-card">
                        <div class="icon">
                            <i class="flaticon-anti-virus-software"></i>
                        </div>
                        <h3>3500+</h3>
                        <p>Customers Served</p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-6 col-md-6">
                    <div class="single-funfacts-card">
                        <div class="icon">
                            <i class="flaticon-cyber"></i>
                        </div>
                        <h3>2500</h3>
                        <p>Cybersecurity Projects</p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-6 col-md-6">
                    <div class="single-funfacts-card">
                        <div class="icon">
                            <i class="flaticon-content"></i>
                        </div>
                        <h3>350+</h3>
                        <p>Cybersecurity Experts</p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-6 col-md-6">
                    <div class="single-funfacts-card">
                        <div class="icon">
                            <i class="flaticon-medal"></i>
                        </div>
                        <h3>150%</h3>
                        <p>Customer Retention</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Funfacts'
}
</script>