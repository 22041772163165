<template>
    <section class="testimonials-area bg-color-f9f9f9 pt-100 pb-70">
        <div class="container">
            <div class="section-title-six">
                <span>Testimonials</span>
                <h2>What Customers Say About Us</h2>
            </div>

            <div class="testimonials">
                <div class="testimonials-slider">
                    <carousel 
                        :autoplay="12000"
                        :settings="settings"
                        :wrap-around="true"
                    >
                        <slide 
                            v-for="slide in carouselItems" 
                            :key="slide.id"
                        >
                            <div class="testimonials-item">
                                <p>{{slide.description}}</p> 

                                <div class="testimonials-view">
                                    <img :src="slide.image" alt="Image">
                                    <h3>{{slide.name}}</h3>
                                    <span>{{slide.position}}</span>
                                </div>
                            </div>
                        </slide>
                        
                        <template #addons>
                            <Navigation />
                        </template>
                    </carousel>
                </div>
                <i class='quote bx bxs-quote-alt-right'></i>
            </div>
        </div>
    </section>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Testimonials',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        isPopup: false,
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                description: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverramaecenas accumsan lacus vel facilisis.”',
                image: require('../../assets/images/testimonials/testimonials-1.jpg'),
                name: 'Jason Doe',
                position: 'Web Developer',
            },
            {
                id: 2,
                description: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverramaecenas accumsan lacus vel facilisis.”',
                image: require('../../assets/images/testimonials/testimonials-1.jpg'),
                name: 'Alex Dew',
                position: 'Web Designer',
            },
            {
                id: 3,
                description: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverramaecenas accumsan lacus vel facilisis.”',
                image: require('../../assets/images/testimonials/testimonials-3.jpg'),
                name: 'Peter Pers',
                position: 'Web Developer',
            },
            {
                id: 4,
                description: '“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverramaecenas accumsan lacus vel facilisis.”',
                image: require('../../assets/images/testimonials/testimonials-4.jpg'),
                name: 'Alex Dew',
                position: 'Web Designer',
            },
        ],
    }),
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
    },
})
</script>