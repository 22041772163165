<template>
    <div class="electronic-area bg-color ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="electronic-content">
                        <h2>Innovative Electronic Protection of Your Office and Home Control Online</h2>
                        <div class="electronic-tab-wrap">
                            <div class="electronic-tab">
                                <div class="tabs">
                                    <ul class="tabs__header">
                                        <li 
                                            class="tabs__header-item" 
                                            v-for="tab in tabs" 
                                            v-on:click="selectTab (tab.id)" 
                                            v-bind:class="{ 'active': activeTab == tab.id }"
                                            :key="tab.id"
                                        >
                                            {{tab.title}}
                                        </li>
                                    </ul>
                                </div>
                                <div class="tabs__container">
                                    <div 
                                        class="tabs__list"
                                        ref='tabsList'
                                    >
                                        <div 
                                            class="tabs__list-tab"
                                            v-for="tab in tabs"
                                            v-bind:class="{ 'active': activeTab == tab.id }"
                                            :key="tab.id"
                                        >
                                            <p>{{tab.descOne}}</p>

                                            <p>{{tab.descTwo}}</p>
                                            <router-link class="default-btn" :to="tab.btnLink">
                                                Learn About
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="electronic-img">
                        <img src="../../assets/images/electronic-img.png" alt="Image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Electronic',
    data () {
        return {
            activeTab: 1,
            offsetRight: 0,

            tabs: [
                {
                    id: 1,
                    title: 'Intercom System',
                    descOne: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo ducimus vero, vero corporis voluptates beatae pariatur laudantium, fugiat illum ab deserunt nostrum aliquid quisquam esse? Voluptatibus quia velit numquam esse porro ipsum dolor, sit amet consectetur adipisicing elit. Illo ducimus vero, corporis.',
                    descTwo: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perspiciatis, soluta, aspernatur dolorum sequi quisquam ullam in pariatur nihil dolorem cumque excepturi totam. Qui excepturi quasi cumque placeat fuga. Ea, eius?',
                    btnLink: '/about-us'
                },
                {
                    id: 2,
                    title: 'CCTV',
                    descOne: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo ducimus vero, vero corporis voluptates beatae pariatur laudantium, fugiat illum ab deserunt nostrum aliquid quisquam esse? Voluptatibus quia velit numquam esse porro ipsum dolor, sit amet consectetur adipisicing elit. Illo ducimus vero, corporis.',
                    descTwo: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perspiciatis, soluta, aspernatur dolorum sequi quisquam ullam in pariatur nihil dolorem cumque excepturi totam. Qui excepturi quasi cumque placeat fuga. Ea, eius?',
                    btnLink: '/about-us'
                },
                {
                    id: 3,
                    title: 'GDPR',
                    descOne: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo ducimus vero, vero corporis voluptates beatae pariatur laudantium, fugiat illum ab deserunt nostrum aliquid quisquam esse? Voluptatibus quia velit numquam esse porro ipsum dolor, sit amet consectetur adipisicing elit. Illo ducimus vero, corporis.',
                    descTwo: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perspiciatis, soluta, aspernatur dolorum sequi quisquam ullam in pariatur nihil dolorem cumque excepturi totam. Qui excepturi quasi cumque placeat fuga. Ea, eius?',
                    btnLink: '/about-us'
                },
                {
                    id: 4,
                    title: 'Encryption',
                    descOne: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo ducimus vero, vero corporis voluptates beatae pariatur laudantium, fugiat illum ab deserunt nostrum aliquid quisquam esse? Voluptatibus quia velit numquam esse porro ipsum dolor, sit amet consectetur adipisicing elit. Illo ducimus vero, corporis.',
                    descTwo: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perspiciatis, soluta, aspernatur dolorum sequi quisquam ullam in pariatur nihil dolorem cumque excepturi totam. Qui excepturi quasi cumque placeat fuga. Ea, eius?',
                    btnLink: '/about-us'
                },
                {
                    id: 5,
                    title: 'Our Goal',
                    descOne: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo ducimus vero, vero corporis voluptates beatae pariatur laudantium, fugiat illum ab deserunt nostrum aliquid quisquam esse? Voluptatibus quia velit numquam esse porro ipsum dolor, sit amet consectetur adipisicing elit. Illo ducimus vero, corporis.',
                    descTwo: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perspiciatis, soluta, aspernatur dolorum sequi quisquam ullam in pariatur nihil dolorem cumque excepturi totam. Qui excepturi quasi cumque placeat fuga. Ea, eius?',
                    btnLink: '/about-us'
                },
            ]
        }
    },
    methods: {
        selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }
    },
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    },
}
</script>