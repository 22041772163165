<template>
    <div>
        <div class="hero-slider-area">
            <div class="hero-slider-wrap">
                <carousel 
                    :autoplay="12000"
                    :settings="settings"
                    :wrap-around="true"
                >
                    <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div :class="slide.class">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="container">
                                        <div class="row align-items-center">
                                            <div class="col-lg-9">
                                                <div class="slider-text one">
                                                    <h1>{{slide.heading}}</h1>
                                                    <p>{{slide.description}}</p>
                
                                                    <div class="slider-btn">
                                                        <router-link class="default-btn" to="/contact">
                                                            Contact Us
                                                        </router-link>
                                                        <router-link class="default-btn active" to="/about-us">
                                                            Learn More
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-3">
                                                <div class="video-btn-animat one">
                                                    <div
                                                        class="video-btn popup-youtube"
                                                        v-on:click="isPopupMethod(isPopup)"
                                                        style="cursor: pointer"
                                                    >
                                                        <i class="bx bx-play"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <Navigation />
                    </template>
                </carousel>
            </div>
        </div>
        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/3F9s6skyeCU"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Banner',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        isPopup: false,
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                class: 'slider-item slider-item-bg-1',
                heading: 'Cyber Security & IT Management',
                description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil architecto laborum eaque! Deserunt maxime, minus quas molestiae reiciendis esse natus nisi iure.',
            },
            {
                id: 2,
                class: 'slider-item slider-item-bg-2',
                heading: 'Cyber Security Is Not Optional',
                description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil architecto laborum eaque! Deserunt maxime, minus quas molestiae reiciendis esse natus nisi iure.',
            },
        ],
    }),
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
    },
})
</script>