<template>
    <div class="solutions-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <h2>High-Performance Solutions</h2>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloribus quam neque quibusdam corrupti aspernatur corporis alias nisi dolorum expedita veritatis voluptates minima.</p>
            </div>

            <div class="row">
                <div class="col-lg-5">
                    <div class="single-solutions solutions-time-bg-1">
                        <div class="solutions-content">
                            <h3>Secure Managed IT</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                            <router-link to="/contact" class="read-more">
                                Read More
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-7">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="single-solutions solutions-time-bg-2">
                                <div class="solutions-content">
                                    <h3>Compliance</h3>
                                    <p>Lorem ipsum dolor sit amet sed, consectetur adipiscing elit do</p>
                                    <router-link to="/contact" class="read-more">
                                        Read More
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="single-solutions solutions-time-bg-3">
                                <div class="solutions-content">
                                    <h3>Cyber Security</h3>
                                    <p>Lorem ipsum dolor sit amet sed, consectetur adipiscing elit do</p>
                                    <router-link to="/contact" class="read-more">
                                        Read More
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Solutions'
}
</script>