<template>
  <div>
    <Navbar />
    <Banner />
    <!--        <Partner /> -->
    <!--        <Performance /> -->
    <!-- <Transform />
    <Pricing />
    <Choose /> -->
    <!-- <Team /> -->
    <div class="mt-5">
        <Solutions />
    </div>
    <!-- <Graph /> -->
    <!-- <Faq />
    <Client /> -->
    <!-- <BlogStyleTwo /> -->
    <FooterTop />
    <FooterBottom />
  </div>
</template>

<script>
import Navbar from "../Layout/Navbar";
import Banner from "../HomeZero/Banner";
//import Partner from '../Common/Partner'
//import Performance from '../HomeZero/Performance'
// import Transform from "../HomeZero/Transform";
// import Pricing from "../Common/Pricing";
// import Choose from "../HomeZero/Choose";
// import Team from "../Common/Team";
import Solutions from "../HomeOne/Solutions.vue";
// import Graph from "../HomeZero/Graph";
// import Faq from "../HomeZero/Faq";
// import Client from "../Common/Client";
// import BlogStyleTwo from "../HomeZero/BlogStyleTwo";
import FooterTop from "../Layout/FooterTop";
import FooterBottom from "../Layout/FooterBottom";

export default {
  name: "HomePageFive",
  components: {
    Navbar,
    Banner,
    //        Partner,
    //        Performance,
    // Transform,
    // Pricing,
    // Choose,
    // Team,
    Solutions,
    // Graph,
    // Faq,
    // Client,
    // BlogStyleTwo,
    FooterTop,
    FooterBottom,
  },
};
</script>
