<template>
    <div class="client-area ptb-100">
        <div class="container">
            <div class="section-title white-title">
                <h2>What Client’s Say About Us</h2>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloribus quam neque quibusdam corrupti aspernatur corporis alias nisi dolorum expedita veritatis voluptates minima.</p>
            </div>

            <div class="client-wrap">
                <carousel 
                    :autoplay="5000"
                    :settings="settings"
                    :wrap-around="true"
                    :breakpoints="breakpoints"
                >
                    <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div class="single-client">
                            <i class="quotes bx bxs-quote-alt-left"></i>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,do eiusmod tempor incididunt ut labore et dolore.</p>

                            <ul>
                                <li>
                                    <i class="bx bxs-star"></i>
                                </li>
                                <li>
                                    <i class="bx bxs-star"></i>
                                </li>
                                <li>
                                    <i class="bx bxs-star"></i>
                                </li>
                                <li>
                                    <i class="bx bxs-star"></i>
                                </li>
                                <li>
                                    <i class="bx bxs-star"></i>
                                </li>
                            </ul>

                            <div class="client-img">
                                <img src="../../assets/images/clients/client-1.jpg" alt="Image">
                                <h3>Alen Meair</h3>
                                <span>Developer</span>
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Client',
    components: {
        Carousel,
        Slide,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },  
        carouselItems: [
            {
                id: 1,
                icon: 'flaticon-left-quotes-sign',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit,do eiusmod tempor incididunt ut labore et dolore.',
                image: require('../../assets/images/clients/client-1.jpg'),
                name: 'Alen Meair',
                position: 'Developer',
            },
            {
                id: 2,
                icon: 'flaticon-left-quotes-sign',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit,do eiusmod tempor incididunt ut labore et dolore.',
                image: require('../../assets/images/clients/client-2.jpg'),
                name: 'Axon Detos',
                position: 'CEO',
            },
            {
                id: 3,
                icon: 'flaticon-left-quotes-sign',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit,do eiusmod tempor incididunt ut labore et dolore.',
                image: require('../../assets/images/clients/client-3.jpg'),
                name: 'John Dona',
                position: 'Designer',
            },
            {
                id: 4,
                icon: 'flaticon-left-quotes-sign',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit,do eiusmod tempor incididunt ut labore et dolore.',
                image: require('../../assets/images/clients/client-4.jpg'),
                name: 'Jon Smith',
                position: 'Developer',
            },
            {
                id: 5,
                icon: 'flaticon-left-quotes-sign',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit,do eiusmod tempor incididunt ut labore et dolore.',
                image: require('../../assets/images/clients/client-2.jpg'),
                name: 'Axon Detos',
                position: 'CEO',
            },
            {
                id: 6,
                icon: 'flaticon-left-quotes-sign',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit,do eiusmod tempor incididunt ut labore et dolore.',
                image: require('../../assets/images/clients/client-3.jpg'),
                name: 'John Dona',
                position: 'Designer',
            },
            {
                id: 7,
                icon: 'flaticon-left-quotes-sign',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit,do eiusmod tempor incididunt ut labore et dolore.',
                image: require('../../assets/images/clients/client-4.jpg'),
                name: 'Jon Smith',
                position: 'Developer',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
        },
    }),
})
</script>