<template>
    <div>
        <Navbar />
        <PageTitle />
        <Features />
        <Performance />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../ServicesStyleTwo/PageTitle'
import Features from '../ServicesStyleTwo/Features'
import Performance from '../ServicesStyleTwo/Performance'
import FooterTop from '../Layout/FooterTop'
import FooterBottom from '../Layout/FooterBottom'

export default {
    name: 'ServicesStyleTwoPage',
    components: {
        Navbar,
        PageTitle,
        Features,
        Performance,
        FooterTop,
        FooterBottom,
    }
}
</script>
