<template>
    <div>
        <Navbar />
        <PageTitle />
        <Approach />
        <Complete />
        <Cybersecurity />
        <Solutions />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../ServicesStyleThree/PageTitle'
import Approach from '../ServicesStyleThree/Approach'
import Complete from '../ServicesStyleThree/Complete'
import Cybersecurity from '../ServicesStyleThree/Cybersecurity'
import Solutions from '../ServicesStyleThree/Solutions'
import FooterTop from '../Layout/FooterTop'
import FooterBottom from '../Layout/FooterBottom'

export default {
    name: 'ServicesStyleThreePage',
    components: {
        Navbar,
        PageTitle,
        Approach,
        Complete,
        Cybersecurity,
        Solutions,
        FooterTop,
        FooterBottom,
    }
}
</script>
