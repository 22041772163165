<template>
  <div class="top-header-area">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-lg-6 col-sm-8">
          <ul class="header-content-left">
            <li>
              <a href="mailto:info@shopandmag.com.tr">
                <i class="bx bx-envelope"></i>
                Email: info@shopandmag.com.tr
              </a>
            </li>

            <li>
              <i class="bx bx-location-plus"></i>
              Ajans 814 Westgate Residance C/79 Ankara - Türkiye
            </li>
          </ul>
        </div>

        <div class="col-lg-6 col-sm-4">
          <ul class="header-content-right">
        

        
            <li>
              <a href="https://www.instagram.com/shopandmag" target="_blank">
                <i class="bx bxl-instagram"></i>
              </a>
            </li>
            <li>
              <a href="https://youtube.com/@ShopandMag" target="_blank">
                <i class="bx bxl-youtube"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopHeader'
}
</script>