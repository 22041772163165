<template>
    <div class="page-title-area bg-1">
        <div class="container">
            <div class="page-title-content">
                <h2>Blog Left Sidebar</h2>
                <ul>
                    <li>
                        <router-link to="/">
                            Home 
                        </router-link>
                    </li>
                    <li class="active">Blog Left Sidebar</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PageTitle'
}
</script>