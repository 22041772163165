<template>
    <body class="home-six">
        <NavbarStyleThree />
        <Banner />
        <Partner />
        <ServicesSlider />
        <Serve />
        <Counter />
        <ChooseUs />
        <Testimonials />
        <Video />
        <Blog />
        <LetsTalk />
        <FooterStyleTwo />
        <CopyRight />
    </body>
</template>

<script>
import NavbarStyleThree from '../Layout/NavbarStyleThree'
import Banner from '../HomeSix/Banner'
import Partner from '../HomeSix/Partner'
import ServicesSlider from '../HomeSix/ServicesSlider'
import Serve from '../HomeSix/Serve'
import Counter from '../HomeSix/Counter'
import ChooseUs from'../HomeSix/ChooseUs'
import Testimonials from '../HomeSix/Testimonials'
import Video from '../HomeSix/Video'
import Blog from '../HomeSix/Blog'
import LetsTalk from '../HomeSix/LetsTalk'
import FooterStyleTwo from '../Layout/FooterStyleTwo'
import CopyRight from '../Layout/CopyRight'

export default {
    name: 'HomePageSix',
    components: {
        NavbarStyleThree,
        Banner,
        Partner,
        ServicesSlider,
        Serve,
        Counter,
        ChooseUs,
        Testimonials,
        Video,
        Blog,
        LetsTalk,
        FooterStyleTwo,
        CopyRight,
    }
}
</script>