<template>
  <div>
    <div class="hero-slider-area">
      <div class="hero-slider-wrap">
        <carousel :autoplay="12000" :settings="settings" :wrap-around="true">
          <slide v-for="(data, index) in Posts" :key="index">
            <div :style="{ backgroundImage: `url(https://backend.shopandmag.com.tr${data.content.properties.imgSlider[0]?.url })` }"
                 class="slider-item">
              <div class="d-table">
                <div class="d-table-cell">
                  <div class="container">
                    <div class="row align-items-center">
                      <div class="col-lg-9">
                        <div class="slider-text one">
                          <h1>{{ data.content.properties.slideHeader }}</h1>
                          <p>{{ data.content.properties.slideDescription || '' }}</p>

                          <div class="slider-btn">
                            <router-link :to="{ path: '/blog-details/' + data.content.properties.pageId }"
                                         class="default-btn active">
                              Devamını Okuyun
                            </router-link>
                          </div>
                        </div>
                      </div>

                      <div v-if=" data.content.properties.linkVideo !== null" class="col-lg-3">
                        <div class="video-btn-animat one">
                          <div class="video-btn popup-youtube"
                               style="cursor: pointer" @click="openPopup(data.content.properties.linkVideo[0].url)">
                            <i class="bx bx-play"></i>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </slide>

          <template #addons>
            <Navigation/>
          </template>
        </carousel>
      </div>
    </div>

    <div v-if="isPopup" class="popup-video">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="popup-overlay-close" @click="closePopup">
            <div class="popup-overlay-close-line"></div>
            <div class="popup-overlay-close-line"></div>
          </div>
          <div class="play-video">
            <iframe :src="popupVideoLink +'?rel=0&modestbranding=1&autohide=1&mute=1&&autoplay=1'" ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import {Carousel, Navigation, Slide} from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

import UmbracoService from "@/services/umbraco.service";

export default defineComponent({
  name: "Banner",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      isPopup: false,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      Posts: [],
      popupVideoLink: "",
    };
  },
  created() {
    UmbracoService.getContentItem()
        .then(data => {
          this.Umb = data;
          console.log("Content fetched successfully:", this.Umb);
          this.Posts = this.Umb.items[0].properties.data.items

          console.log("Filtered title:", this.Posts);
        })
        .catch(error => {
          console.error('Error fetching content:', error);
        });


  },
  methods: {
    openPopup(linkvideo) {
      this.isPopup = true;
      this.popupVideoLink = linkvideo;
    },
    closePopup() {
      this.isPopup = false;
      this.popupVideoLink = "";
    },
  },
});
</script>
