<template>
    <div class="operation-center-area pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="operation-center-image">
                        <img src="../../assets/images/more-home/operation-center/operation-center.jpg" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="operation-center-content white-color-content">
                        <h3>24/7 Cybersecurity Operation Center For You</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit adipiscing eleifend dictum potenti mattis viverra eget quam lacus enim porttitor bibendum elit dui nisl.</p>

                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <ul class="operation-list">
                                    <li>
                                        <span><i class='bx bx-check'></i> Managed Web Application</span>
                                    </li>
                                    <li>
                                        <span><i class='bx bx-check'></i> SIEM Threat Detection</span>
                                    </li>
                                    <li>
                                        <span><i class='bx bx-check'></i> Content Delivery Network</span>
                                    </li>
                                    <li>
                                        <span><i class='bx bx-check'></i> 24/7 Hours Services</span>
                                    </li>
                                    <li>
                                        <span><i class='bx bx-check'></i> Security Management</span>
                                    </li>
                                </ul>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <ul class="operation-list">
                                    <li>
                                        <span><i class='bx bx-check'></i> Instant Malware Removal</span>
                                    </li>
                                    <li>
                                        <span><i class='bx bx-check'></i> Free Delivary Services</span>
                                    </li>
                                    <li>
                                        <span><i class='bx bx-check'></i> Website Hack Repair</span>
                                    </li>
                                    <li>
                                        <span><i class='bx bx-check'></i> Website Security Services</span>
                                    </li>
                                    <li>
                                        <span><i class='bx bx-check'></i> Provide Security Services</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CybersecurityOperation'
}
</script>