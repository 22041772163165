<template>
    <div>
        <Error />
    </div>
</template>

<script>
import Error from '../Error/Error'

export default {
    name: 'ErrorPage',
    components: {
        Error,
    }
}
</script>
