<template>
    <div class="services-area ptb-100">
        <div class="container">
            <div class="section-title-six">
                <span>Our Services</span>
                <h2>We Offer Best Cyber Security Solutions</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="services-slider-content">
                <div class="services-slider">
                    <carousel 
                        :autoplay="12000"
                        :settings="settings"
                        :wrap-around="true"
                    >
                        <slide 
                            v-for="slide in carouselItems" 
                            :key="slide.id"
                        >
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="services-single-item">
                                        <h3>{{slide.title}}</h3>

                                        <p>{{slide.descOne}}</p>
            
                                        <p>{{slide.descTwo}}</p>
                                        
                                        <router-link  class="default-btn six" :to="slide.btnLink">
                                            <i class="bx bx-file"></i>
                                            {{slide.btnText}}
                                        </router-link>
                                    </div>
                                </div>

                                <div class="col-lg-6">
                                    <div class="services-img">
                                        <img :src="slide.image" alt="Image">
                                    </div>
                                </div>
                            </div>
                        </slide>

                        <template #addons>
                            <Navigation />
                        </template>
                    </carousel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'ServicesSlider',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        isPopup: false,
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                title: 'Security Advisory',
                descOne: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo ducimus vero, vero corporis voluptates beatae pariatur laudantium, fugiat illum ab deserunt nostrum aliquid quisquam esse? Voluptatibus quia velit numquam esse porro ipsum dolor, sit amet consectetur adipisicing elit. Illo ducimus vero, corporis Voluptatibus quia.',
                descTwo: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perspiciatis, soluta, aspernatur dolorum sequi quisquam ullam in pariatur nihil dolorem cumque excepturi totam. Qui excepturi quasi cumque placeat fuga. Ea, eius nihil dolorem cumque',
                btnText: 'Learn More',
                btnLink: '/about-us',
                image: require('../../assets/images/services-slider/services-1.png'),
            },
            {
                id: 2,
                title: 'Technical Assurance',
                descOne: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo ducimus vero, vero corporis voluptates beatae pariatur laudantium, fugiat illum ab deserunt nostrum aliquid quisquam esse? Voluptatibus quia velit numquam esse porro ipsum dolor, sit amet consectetur adipisicing elit. Illo ducimus vero, corporis Voluptatibus quia.',
                descTwo: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perspiciatis, soluta, aspernatur dolorum sequi quisquam ullam in pariatur nihil dolorem cumque excepturi totam. Qui excepturi quasi cumque placeat fuga. Ea, eius nihil dolorem cumque',
                btnText: 'Learn More',
                btnLink: '/about-us',
                image: require('../../assets/images/services-slider/services-2.png'),
            },
            {
                id: 3,
                title: 'Assessment Services',
                descOne: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo ducimus vero, vero corporis voluptates beatae pariatur laudantium, fugiat illum ab deserunt nostrum aliquid quisquam esse? Voluptatibus quia velit numquam esse porro ipsum dolor, sit amet consectetur adipisicing elit. Illo ducimus vero, corporis Voluptatibus quia.',
                descTwo: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perspiciatis, soluta, aspernatur dolorum sequi quisquam ullam in pariatur nihil dolorem cumque excepturi totam. Qui excepturi quasi cumque placeat fuga. Ea, eius nihil dolorem cumque',
                btnText: 'Learn More',
                btnLink: '/about-us',
                image: require('../../assets/images/services-slider/services-3.png'),
            },
            {
                id: 4,
                title: 'Threat Hunter',
                descOne: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo ducimus vero, vero corporis voluptates beatae pariatur laudantium, fugiat illum ab deserunt nostrum aliquid quisquam esse? Voluptatibus quia velit numquam esse porro ipsum dolor, sit amet consectetur adipisicing elit. Illo ducimus vero, corporis Voluptatibus quia.',
                descTwo: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perspiciatis, soluta, aspernatur dolorum sequi quisquam ullam in pariatur nihil dolorem cumque excepturi totam. Qui excepturi quasi cumque placeat fuga. Ea, eius nihil dolorem cumque',
                btnText: 'Learn More',
                btnLink: '/about-us',
                image: require('../../assets/images/services-slider/services-4.png'),
            },
            {
                id: 5,
                title: 'Splunk Services',
                descOne: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo ducimus vero, vero corporis voluptates beatae pariatur laudantium, fugiat illum ab deserunt nostrum aliquid quisquam esse? Voluptatibus quia velit numquam esse porro ipsum dolor, sit amet consectetur adipisicing elit. Illo ducimus vero, corporis Voluptatibus quia.',
                descTwo: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Perspiciatis, soluta, aspernatur dolorum sequi quisquam ullam in pariatur nihil dolorem cumque excepturi totam. Qui excepturi quasi cumque placeat fuga. Ea, eius nihil dolorem cumque',
                btnText: 'Learn More',
                btnLink: '/about-us',
                image: require('../../assets/images/services-slider/services-5.png'),
            },
        ],
    }),
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
    },
})
</script>