<template>
    <div class="copy-right-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>&copy; {{currentYear}} shop&mag</p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul class="footer-menu">
                        <li>
                            <router-link to="/privacy-policy" target="_blank">
                                Privacy Policy 
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/terms-conditions" target="_blank">
                                Terms & Conditions
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CopyRight',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>