<template>
    <div class="solutions-area section-width pb-100">
        <div class="container">
            <div class="section-title">
                <h2>High-Performance Solutions</h2>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloribus quam neque quibusdam corrupti aspernatur corporis alias nisi dolorum expedita veritatis voluptates minima.</p>
            </div>

            <div class="row">
                <div class="col-lg-5">
                    <div class="single-solutions solutions-time-bg-1">
                        <div class="solutions-content">
                            <h3>Secure Managed ITaaaa</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorer</p>
                            <router-link to="/services-details" class="read-more">
                                Read More
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-7">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="single-solutions solutions-time-bg-2">
                                <div class="solutions-content">
                                    <h3>Compliance</h3>
                                    <p>Lorem ipsum dolor sit amet sed, consectetur adipiscing elit do</p>
                                    <router-link to="/services-details" class="read-more">
                                        Read More
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="single-solutions solutions-time-bg-3">
                                <div class="solutions-content">
                                    <h3>Cyber Security</h3>
                                    <p>Lorem ipsum dolor sit amet sed, consectetur adipiscing elit do</p>
                                    <router-link to="/services-details" class="read-more">
                                        Read More
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-7">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="single-solutions solutions-time-bg-4">
                                <div class="solutions-content">
                                    <h3>Disaster Planning</h3>
                                    <p>Lorem ipsum dolor sit amet sed, consectetur adipiscing elit do</p>
                                    <router-link to="/services-details" class="read-more">
                                        Read More
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="single-solutions solutions-time-bg-5">
                                <div class="solutions-content">
                                    <h3>Secure By Design</h3>
                                    <p>Lorem ipsum dolor sit amet sed, consectetur adipiscing elit do</p>
                                    <router-link to="/services-details" class="read-more">
                                        Read More
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-5">
                    <div class="single-solutions solutions-time-bg-6">
                        <div class="solutions-content">
                            <h3>Secure Awareness Training</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorer</p>
                            <router-link to="/services-details" class="read-more">
                                Read More
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12">
                    <div class="page-navigation-area">
                        <nav aria-label="Page navigation example text-center">
                            <ul class="pagination">
                                <li class="page-item">
                                    <a class="page-link page-links" href="#">
                                        <i class='bx bx-chevrons-left'></i>
                                    </a>
                                </li>
                                <li class="page-item active">
                                    <a class="page-link" href="#">1</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#">2</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#">3</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#">
                                        <i class='bx bx-chevrons-right'></i>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Solutions'
}
</script>