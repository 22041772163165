<template>
    <div class="testimonials-wrap-area ptb-100">
        <div class="container">
            <div class="section-title white-title">
                <h2>What Client’s Say About Us</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit adipiscing eleifend dictum potenti mattis viverra eget quam lacus enim porttitor bibendum elit dui nisl.</p>
            </div>

            <div class="testimonials-wrap-slides">
                <carousel 
                    :autoplay="5000"
                    :settings="settings"
                    :breakpoints="breakpoints"
                >
                    <slide 
                        v-for="slide in carouselItems" 
                        :key="slide.id"
                    >
                        <div class="single-testimonials-card">
                            <p>{{slide.description}}</p>
                            <div class="client-info">
                                <h3>{{slide.name}}</h3>
                                <span>{{slide.position}}</span>
                            </div>
                            <ul class="rating">
                                <li><i class="bx bxs-star"></i></li>
                                <li><i class="bx bxs-star"></i></li>
                                <li><i class="bx bxs-star"></i></li>
                                <li><i class="bx bxs-star"></i></li>
                                <li><i class="bx bxs-star"></i></li>
                            </ul>
                            <div class="quote-shape">
                                <img src="../../assets/images/more-home/testimonials/shape.png" alt="image">
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <Pagination />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Testimonials',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                description: 'Lorem ipsum dolor amet consectet adipiscing elit adipiscing eleifend dicturi me pot nti mattis viverra.',
                name: 'Patric Watler',
                position: 'Manager',
            },
            {
                id: 2,
                description: 'Lorem ipsum dolor amet consectet adipiscing elit adipiscing eleifend dicturi me pot nti mattis viverra.',
                name: 'Jane Anderson',
                position: 'Developer',
            },
            {
                id: 3,
                description: 'Lorem ipsum dolor amet consectet adipiscing elit adipiscing eleifend dicturi me pot nti mattis viverra.',
                name: 'Victor James',
                position: 'Designer',
            },
            {
                id: 4,
                description: 'Lorem ipsum dolor amet consectet adipiscing elit adipiscing eleifend dicturi me pot nti mattis viverra.',
                name: 'Patric Watler',
                position: 'Manager',
            },
            {
                id: 5,
                description: 'Lorem ipsum dolor amet consectet adipiscing elit adipiscing eleifend dicturi me pot nti mattis viverra.',
                name: 'Jane Anderson',
                position: 'Developer',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
        },
    }),
})
</script>