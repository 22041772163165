<template>
    <div>
        <Navbar />
        <PageTitle />
        <ContactInfo />
        <Contact />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../Contact/PageTitle'
import ContactInfo from '../Contact/ContactInfo'
import Contact from '../Contact/Contact'
import FooterTop from '../Layout/FooterTop'
import FooterBottom from '../Layout/FooterBottom'

export default {
    name: 'ContactPage',
    components: {
        Navbar,
        PageTitle,
        ContactInfo,
        Contact,
        FooterTop,
        FooterBottom,
    }
}
</script>