<template>
  <div class="header-area fixed-top">
    <TopHeader />

    <div class="nav-area">
      <div :class="['navbar-area', { 'is-sticky': isSticky }]">
        <div class="main-nav">
          <nav class="navbar navbar-expand-lg">
            <div class="container-fluid">
              <router-link class="navbar-brand" to="/">
                <img
                  src="../../assets/images/ShopAndMag-Logo-Header.png"
                  alt="image"
                />
              </router-link>

              <div
                class="navbar-toggler"
                @click="active = !active"
                :aria-pressed="active ? 'true' : 'false'"
                v-bind:class="{ active: button_active_state }"
                v-on:click="button_active_state = !button_active_state"
              >
                <span class="icon-bar top-bar"></span>
                <span class="icon-bar middle-bar"></span>
                <span class="icon-bar bottom-bar"></span>
              </div>

              <div class="collapse navbar-collapse" :class="{ show: active }">
                <ul class="navbar-nav m-auto">
                  <li
                    class="nav-item"
                    v-for="(data, index) in NavbarItems.data"
                    :key="index"
                  >
                    <div v-if="data.child" class="nav-link parent_nav">
                      {{ data.linkText }}
                      <i class="bx bx-chevron-right"></i>
                      <ul class="dropdown-menu">
                        <li
                          class="nav-link"
                          v-for="(data, index) in data.submenu"
                          :key="index"
                        >
                          <router-link
                            :to="{ path: '/blog-grid/' + data.id }"
                            class="nav-link"
                            exact
                          >
                            {{ data.linkText }}
                          </router-link>
                        </li>
                      </ul>
                    </div>

                    <router-link v-else :to="`${data.link}`" class="nav-link">
                      {{ data.linkText }}
                      <i v-if="data.child" class="bx bx-chevron-right"></i>
                    </router-link>
                  </li>

                  <!-- <li class="nav-item">
                                        <router-link to="/about-us" class="nav-link">
                                            Shop&Mag
                                        </router-link>
                                    </li>

                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            Moda
                                            <i class='bx bx-chevron-right'></i>
                                        </a>

                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <router-link to="/pricing" class="nav-link">
                                                    Pricing
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/testimonials" class="nav-link">
                                                    Testimonials
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/team" class="nav-link">
                                                    Team
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/faq" class="nav-link">
                                                    FAQ
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <a href="#" class="nav-link">
                                                    User 
                                                    <i class='bx bx-chevron-right'></i>
                                                </a>

                                                <ul class="dropdown-menu">
                                                    <li class="nav-item">
                                                        <router-link to="/log-in" class="nav-link">
                                                            Log In
                                                        </router-link>
                                                    </li>

                                                    <li class="nav-item">
                                                        <router-link to="/sign-in" class="nav-link">
                                                            Sign In
                                                        </router-link>
                                                    </li>

                                                    <li class="nav-item">
                                                        <router-link to="/recover-password" class="nav-link">
                                                            Recover Password
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/terms-conditions" class="nav-link">
                                                    Terms & Conditions
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/privacy-policy" class="nav-link">
                                                    Privacy Policy
                                                </router-link>
                                            </li>
                                        
                                            <li class="nav-item">
                                                <router-link to="/error" class="nav-link">
                                                    Error Page
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            Kişisel Bakım
                                            <i class='bx bx-chevron-right'></i>
                                        </a>

                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                            <router-link to="/services-one" class="nav-link">
                                                Services One
                                            </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/services-two" class="nav-link">
                                                    Services Two
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/services-three" class="nav-link">
                                                    Services Three
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/services-details" class="nav-link">
                                                    Services Details
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li class="nav-item">
                                        <a href="#" class="nav-link">
                                            Deneyim
                                            <i class='bx bx-chevron-right'></i>
                                        </a>

                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <router-link to="/blog-grid" class="nav-link">
                                                    Blog Grid
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/blog-left-sidebar" class="nav-link">
                                                    Blog Left Sidebar
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/blog-right-sidebar" class="nav-link">
                                                    Blog Right Sidebar
                                                </router-link>
                                            </li>

                                            <li class="nav-item">
                                                <router-link to="/blog-details" class="nav-link">
                                                    Blog Details
                                                </router-link>
                                            </li>
                                        </ul>
                                    </li>

                                    <li class="nav-item">
                                        <router-link to="/contact" class="nav-link">
                                            Alanlar
                                        </router-link>
                                    </li> -->
                </ul>
              </div>

              <div class="others-option">
                <div class="get-quote">
<!--                  <a href="/contact" class="default-btn"> Üyelik </a>-->
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "../Layout/TopHeader";
import NavbarItems from "../../assets/data/NavbarItems.json";

export default {
  name: "Navbar",
  components: {
    TopHeader,
  },
  data() {
    return {
      isSticky: false,
      active: false,
      button_active_state: false,
      NavbarItems,
    };
  },
  mounted() {
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = true;
      } else {
        that.isSticky = false;
      }
    });
  },
};
</script>

<style scoped>
.parent_nav{
  font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    padding: 9px 15px;
    margin: 0;
    display: block;
    color: #ffffff;
    position: relative;
    padding-top: 2px;

    transition: all 0.3s ease-in-out;
}
.parent_nav:hover{
  color: #FF2D55;
}
</style>