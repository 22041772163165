<template>
    <div class="choose-us-six-area ptb-100">
        <div class="container">
            <div class="section-title-six">
                <span>Why Choose Us</span>
                <h2>We Different From Others</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="choose-us-six-content">
                <div class="tab">
                    <div class="choose-content">
                        <div class="choose-tab-wrap">
                            <div class="choose-tab">
                                <div class="tabs">
                                    <ul class="tabs__header">
                                        <li 
                                            class="tabs__header-item" 
                                            v-for="tab in tabs" 
                                            v-on:click="selectTab (tab.id)" 
                                            v-bind:class="{ 'active': activeTab == tab.id }"
                                            :key="tab.id"
                                        >
                                            {{tab.title}}
                                        </li>
                                    </ul>
                                </div>
                                <div class="tabs__container">
                                    <ul 
                                        class="tabs__list"
                                        ref='tabsList'
                                    >
                                        <li 
                                            class="tabs__list-tab"
                                            v-for="tab in tabs"
                                            v-bind:class="{ 'active': activeTab == tab.id }"
                                            :key="tab.id"
                                        >
                                            <div class="row align-items-center justify-content-center">
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="choose-us-content">
                                                        <div class="choose-us">
                                                            <h3>{{tab.title}}</h3>
                                                            <p>{{tab.desc}}</p>
                                                        </div>
                                                        <div class="shape-1">
                                                            <img src="../../assets/images/shape/shape-11.png" alt="Image">
                                                        </div>
                                                        <div class="shape-3">
                                                            <img src="../../assets/images/shape/shape-13.png" alt="Image">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6">
                                                    <div class="choose-us-img">
                                                        <img :src="tab.image" alt="Image">

                                                        <div class="shape-2">
                                                            <img src="../../assets/images/shape/shape-12.png" alt="Image">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChooseUs',
    data (){
        return {
            activeTab: 1,
            offsetRight: 0,
            
            tabs: [
                {
                    id: 1,
                    title: 'Industry Experts',
                    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore',
                    image: require('../../assets/images/choose-us/choose-1.jpg'),
                },
                {
                    id: 2,
                    title: 'Dedicated Team',
                    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore',
                    image: require('../../assets/images/choose-us/choose-2.jpg'),
                },
                {
                    id: 3,
                    title: 'Outcome Focused',
                    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore',
                    image: require('../../assets/images/choose-us/choose-3.jpg'),
                },
                {
                    id: 4,
                    title: 'High Quality Service',
                    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore',
                    image: require('../../assets/images/choose-us/choose-4.jpg'),
                },
                {
                    id: 5,
                    title: 'Cyber Security Expert',
                    desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore',
                    image: require('../../assets/images/choose-us/choose-5.jpg'),
                },
            ]
        }
    },
    methods: {
        selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }
    }, 
    mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    },
}
</script>