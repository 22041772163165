<template>
  <footer class="footer-top-area pt-100 pb-70">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="single-widget contact">
            <h3>İletişim</h3>

            <ul>
              <!--<li>
                <a href="tel:Phone:+892-569-756">
                  <i class="bx bx-phone-call"></i>
                  <span>Telefon</span>
                  +892-569-756
                </a>
              </li>-->

              <li>
                <a href="mailto:hello@quvia.com">
                  <i class="bx bx-envelope"></i>
                  <span>Email:</span>
                  info@shopandmag.com.tr
                </a>
              </li>

              <li>
                <i class="bx bx-location-plus"></i>
                <span>Adres:</span>
                Ajans 814 Westgate Residance C/79 Ankara - Türkiye
              </li>
            </ul>
          </div>
        </div>

        <div
          class="col-lg-3 col-md-6"
          v-for="(data, index) in NavbarItems.data.slice(2,5)"
          :key="index"
        >
          <div class="single-widget">
            <h3>{{ data.linkText }}</h3>

            <ul>
              <li v-for="(data, index) in data.submenu" :key="index">
                <router-link :to="{ path: '/blog-grid/' + data.id }">
                  <i class="bx bx-chevrons-right"></i>
                  {{ data.linkText }}
                </router-link> 
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import NavbarItems from "../../assets/data/NavbarItems.json";
export default {
  name: "FooterTop",
  data() {
    return {
      NavbarItems,
    };
  },
  //   computed: {
  //   FooterNav: function () {
  //     return this.NavbarItems.data.filter(i => i.child === true)
  //   },
  // }
};
</script>