<template>
    <div>
        <Navbar />
        <PageTitle />
        <BlogLeftSidebar />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
import Navbar from '../Layout/Navbar'
import PageTitle from '../BlogLeftSidebar/PageTitle'
import BlogLeftSidebar from '../BlogLeftSidebar/BlogLeftSidebar'
import FooterTop from '../Layout/FooterTop'
import FooterBottom from '../Layout/FooterBottom'

export default {
    name: 'BlogLeftSidebarPage',
    components: {
        Navbar,
        PageTitle,
        BlogLeftSidebar,
        FooterTop,
        FooterBottom,
    }
}
</script>